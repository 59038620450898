/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const myList = ['Localized Treatment: Micropigmentation is designed to treat specific areas of depigmentation, ensuring that the color of the treated areas matches the surrounding skin precisely. This technique is particularly effective for smaller, localized white spots.', 'Safe and Non-Invasive: This treatment is safe for all skin types and involves minimal discomfort. As a non-invasive procedure, micropigmentation requires no downtime, allowing patients to resume their daily activities immediately after treatment.', 'Effective for Stable Vitiligo: Patients with stable vitiligo can benefit greatly from micropigmentation, as it offers a reliable solution for camouflaging white spots and improving the overall appearance of the skin.', 'Long-Lasting Results: The pigments used in micropigmentation are durable and long-lasting, ensuring that the treated areas maintain their appearance over time. This makes it an excellent option for those seeking a permanent solution to their vitiligo.'];
const myComponentList = myList.map((item, index) => (
  <li key={index}>{item}</li>
));
  
function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
      <MKTypography variant="h3" gutterBottom>
      Vitiligo Treatment in Jaipur by Micropigmentation: Effective and Long-Lasting
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Micropigmentation is an advanced technique used to treat vitiligo, a condition that causes white spots on the skin. This medical tattooing procedure involves implanting pigment into the skin to match the surrounding areas, effectively camouflaging the white spots. In Jaipur, experienced dermatologists offer this treatment as a non-invasive option that provides reliable and long-lasting results.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Why Choose Micropigmentation for Vitiligo in Jaipur?
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          For patients with stable vitiligo, micropigmentation offers a targeted, effective solution. The treatment is particularly beneficial for those looking to improve the appearance of their skin without undergoing invasive procedures. The pigments used are carefully matched to the patient’s natural skin tone, ensuring a seamless blend.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits of Micropigmentation for Vitiligo
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            {myComponentList}
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
