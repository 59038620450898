/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
          <MKTypography variant="h3" gutterBottom>
          Why Choose Laser Toning?
          </MKTypography>
          <MKTypography variant="body1" paragraph>
          Laser toning is a highly effective and minimally invasive procedure with minimal downtime. It is suitable for all skin types and can be customized based on your specific skin needs. The treatment involves gentle laser beams that penetrate the skin's layers to reduce pigmentation and improve overall skin health.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          What to Expect:
          </MKTypography>
          <MKTypography variant="body1" paragraph>
          During the treatment, you may experience a slight tingling sensation. Post-treatment, there might be mild redness, which usually subsides within a few hours. For optimal results, it’s essential to follow post-care instructions, including sun protection and hydration.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Perfect for Treating:
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            <li>Hyperpigmentation: Dark spots and uneven skin tone.</li>
            <li>Acne Scars: Diminishes residual marks from past breakouts.</li>
            <li>Sun Damage: Reduces the effects of prolonged sun exposure.</li>
            <li>Fine Lines and Wrinkles: Smoothens and revitalizes aging skin.</li>
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          At Skinaa Clinic Malviya Nagar, our expert dermatologists use state-of-the-art laser technology to deliver safe and effective treatments tailored to your skin type. Experience the transformative power of laser toning and achieve a flawless, glowing complexion.
          </MKTypography>
          <MKTypography variant="h3" paragraph>
          Book Your Consultation Today!
          </MKTypography>
          <MKTypography variant="h4" paragraph>
          Contact us at +91-7300009731 to schedule your laser toning session and start your journey to radiant skin.
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
