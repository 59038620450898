/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const FAQ = () => {
const Que = ['How Much Time the Excimer Laser Treatment Take?', 'How Many Patients Have You Treated So Far?', 'Is This Treatment Safe?'];
const Ans = ['The treatment can take a few minutes to a few hours depending on the size of vitiligo. The patient needs to come to the clinic at least two times a day for this treatment.', 'At Skinaa, we can say with immense pride that we have treated more than a thousand vitiligo patients. Our vitiligo patients belong to not only small villages of India but also some big cities of the world such as San Francisco.', 'Yes, it is completely safe with zero side effects.'];

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h4" color="white">
            FAQs (Vitiligo Treatment by Excimer Laser)
            </MKTypography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
          {Que.map((Que, index) => (
          <Stack key={index}>
            <MKBox  display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                <MKTypography variant="h4" color="text" pl={2}>
                  {Que}
                  </MKTypography>
            </MKBox>
              <MKTypography variant="body" color="text" pl={2}>
                {Ans[index]}
              </MKTypography>
          </Stack>
      ))} 
        </Grid>
      </Container>
    </MKBox>
)};
export default FAQ;
