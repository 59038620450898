// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h3" my={1}>
              Chemical Peel For Pigmentation in Jaipur
            </MKTypography>
            <MKTypography variant="body" color="text" mb={2}>
              Chemical peel treatment in Jaipur at Skinaa Clinic is an advanced peel treatment that
              helps you get rid of dull-looking skin & spots.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <MKTypography variant="body2" my={1}>
              When the skin gets exposed to the sun it burns and turns dead. If the dead skin doesn’t get replaced by a new one, dark patches can be observed in certain parts of the skin. These patches are called hyperpigmentation or melasma. Chemical peeling is a verified and effective procedure to get rid of the hyperpigmentation problem. A chemical peel is made of specific acids that remove the external dead skin (Epidermis) and reduce the pigmentation over time.<br />
            </MKTypography>
            <MKTypography variant="body2" my={2}>
              At Skinaa, a dermatologist will check your skin and determine which kind of chemical peel will be best suited. Taking necessary precautions such as staying away from the sun after a few days of the treatment and taking the prescribed medicines and lotions will help your skin heal faster and bring better results. If a patient is suffering from age spots, melasma, sun damage, or blotchy skin, chemical peeling is the best treatment she has.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
