/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function FAQ() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h4" color="white">
            FAQs  (Carbon Laser Facial)
            </MKTypography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
          <Stack>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                  What is Aftercare for Carbon Laser Facial?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                One should treat your skin very gently. For example, using moisturizing every day is very important because your skin might begin to feel dry. And before going to Sun, always use sunscreen. Also, do not exfoliate the skin.
                </MKTypography>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                  Is Carbon Laser Facial Really effective?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                Carbon laser facial is a very effective and advanced technique to treat pigmentation, oily skin problem, and open pores.
                </MKTypography>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                  Are There Any Side Effects?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                Carbon laser facial is a procedure that is usually side effects-free. However, one might experience slight redness on your skin or some kind of tingling sensation. All you need to do is follow the prescriptions and instructions.
                </MKTypography>
          </Stack>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FAQ;
