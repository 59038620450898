/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/Chemical-Peel.jpg";

const description = (
  <>
   Blister grafting is a specialized dermatological treatment designed to address white spots, often caused by conditions like vitiligo. This procedure involves creating small blisters on the patient’s pigmented skin using suction, which are then transplanted onto the white spots. The new skin cells gradually repopulate the depigmented areas, leading to a more even skin tone. Blister grafting is known for its precision and minimal invasiveness, making it a preferred option for treating localized white spots.
  </>
);

function BlisterGraphting() {
  return (
    <>
      <Helmet>
        <title>Blister Grafting Treatment for White Spots - Effective, Precise, and Safe</title>
        <meta name="description" content="Achieve even skin tone with blister grafting, a safe and precise treatment for white spots caused by conditions like vitiligo. This minimally invasive procedure promotes natural repigmentation, offering effective results for small areas of depigmentation." />
        <meta name="keywords" content="Blister grafting treatment, White spots treatment, Vitiligo blister grafting, Safe blister grafting for white spots, Effective repigmentation treatment, Skin grafting for white spots, Dermatological treatment for vitiligo, Precise white spot treatment" />
        <meta property="og:title" content="Blister Grafting Treatment for White Spots - Effective, Precise, and Safe" />
        <meta property="og:description" content="Achieve even skin tone with blister grafting, a safe and precise treatment for white spots caused by conditions like vitiligo. This minimally invasive procedure promotes natural repigmentation, offering effective results for small areas of depigmentation." />
        <meta property="og:url" content="https://www.yoursite.com/blister-grafting-treatment" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Blister Grafting Treatment for White Spots - Effective, Precise, and Safe
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default BlisterGraphting;
