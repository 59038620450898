/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/mole.png";

const description = (
  <>
   Laser mole removal is a precise and safe treatment designed to eliminate unwanted moles from the skin with minimal discomfort and scarring. This advanced procedure uses targeted laser energy to break down the pigment in the mole, allowing it to be naturally absorbed by the body. Suitable for both benign and cosmetically undesirable moles, laser mole removal is an effective solution for achieving smooth, clear skin without the need for invasive surgery.
  </>
);

function MoleRemoval() {
  return (
    <>
      <Helmet>
        <title>Laser Mole Removal Treatment - Precise, Safe, and Quick</title>
        <meta name="description" content="Achieve smooth, clear skin with precise and safe laser mole removal. This minimally invasive treatment offers quick results with minimal discomfort, suitable for all skin types. Choose laser mole removal for a reliable solution to unwanted moles." />
        <meta name="keywords" content="Laser mole removal, Mole removal treatment, Safe mole removal, Effective mole removal with laser, Non-invasive mole removal, Quick mole removal procedure, Laser treatment for moles, Precise mole removal with laser" />
        <meta property="og:title" content="Laser Mole Removal Treatment - Precise, Safe, and Quick" />
        <meta property="og:description" content="Achieve smooth, clear skin with precise and safe laser mole removal. This minimally invasive treatment offers quick results with minimal discomfort, suitable for all skin types. Choose laser mole removal for a reliable solution to unwanted moles." />
        <meta property="og:url" content="https://www.skinaamalviyanagar.com/treatments/laser-mole-removal" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Laser Mole Removal Treatment - Precise, Safe, and Quick
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default MoleRemoval;
