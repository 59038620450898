import React from 'react';
import Fab from '@mui/material/Fab';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const FloatingButtons = () => {
  const handleWhatsAppClick = () => {
      window.open('https://wa.me/917300009731', '_blank');
  };

  const handlePhoneClick = () => {
      window.open('tel:+91-7300009731');
  };
  return (
    <>
          {/* WhatsApp Floating Button */}
          <Fab 
              color="primary" 
              aria-label="whatsapp" 
              onClick={handleWhatsAppClick}
              style={{
                  position: 'fixed',
                  bottom: '90px',
                  right: '20px',
                  backgroundColor: '#25D366',
                  color: '#fff',
                  fontSize: '30px',
              }}
          >
               <WhatsAppIcon />
          </Fab>

          {/* Phone Call Floating Button */}
          <Fab 
              color="secondary" 
              aria-label="call" 
              onClick={handlePhoneClick}
              style={{
                  position: 'fixed',
                  bottom: '20px',
                  right: '20px',
                  backgroundColor: '#4caf50',
                  color: '#fff',
                  fontSize: '30px',
              }}
          >
               <PhoneIcon />
          </Fab>
      </>
  );
};

export default FloatingButtons;
