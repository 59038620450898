/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const FAQ = () => {
const Que = ['Know More About Acne?', 'What Causes Acne?'];
const Ans = ['Acne can actually affect you at any stage of your life. However, people who are more prone to get stuck by acne are teenagers. The undergoing hormonal changes in teenagers sometimes trigger acne. It might be mild for example a few pimples which occur and vanish. Mild acne is absolutely normal and there shouldn’t be any need for treatment for this one. But when it comes to mild and severe acne when the pimples start to cause irritation, they are more persistent and mess with your beauty. That’s the states when you need medical assistance and quickly look for a dermatologist.', 'Bacteria and Oil are the two primary culprits behind acne. It is a hormonal condition driven by androgenic hormones. Certain areas of our body which majorly combines face, chest, back and neck contains oil glands. When the oil glands produce fatty acids, and it accumulates in the skin pores or hair follicles, it causes acne.'];

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h4" color="white">
            FAQs (Acne Scar Treatment by PRP, Dermaroller & Subcision)
            </MKTypography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
          {Que.map((Que, index) => (
          <Stack key={index}>
            <MKBox  display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                <MKTypography variant="h4" color="text" pl={2}>
                  {Que}
                  </MKTypography>
            </MKBox>
              <MKTypography variant="body" color="text" pl={2}>
                {Ans[index]}
              </MKTypography>
          </Stack>
      ))} 
        </Grid>
      </Container>
    </MKBox>
)};
export default FAQ;
