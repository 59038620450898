/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/punchgrafting.jpg";

const description = (
  <>
   Punch grafting is a specialized surgical technique used to treat white spots on the skin, particularly in conditions like vitiligo. This procedure involves the removal of small sections of skin (grafts) from a donor site, which are then transplanted into the depigmented areas. Punch grafting is particularly effective for patients with stable vitiligo, offering a reliable solution to restore skin color and texture.
  </>
);

function PunchGraphting() {
  return (
    <>
      <Helmet>
        <title>Punch Grafting for White Spots - Precise, Effective, and Reliable</title>
        <meta name="description" content="Discover effective treatment for white spots with punch grafting. This minimally invasive technique is ideal for stable vitiligo, providing precise skin repigmentation with natural-looking results." />
        <meta name="keywords" content="Punch grafting for white spots, Vitiligo punch grafting, Skin grafting for vitiligo treatment, Effective vitiligo surgical treatment, Minimally invasive white spots treatment, Reliable vitiligo cure in Jaipur, Natural skin repigmentation for vitiligo, Surgical treatment for white spots" />
        <meta property="og:title" content="Punch Grafting for White Spots - Precise, Effective, and Reliable" />
        <meta property="og:description" content="Discover effective treatment for white spots with punch grafting. This minimally invasive technique is ideal for stable vitiligo, providing precise skin repigmentation with natural-looking results." />
        <meta property="og:url" content="https://www.skinaamalviyanagar.com/treatments/punch-grafting-for-white-spots" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Punch Grafting for White Spots - Precise, Effective, and Reliable
              </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default PunchGraphting;
