/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";


const myList = ['Volume Restoration: Effective in replenishing volume in areas like the cheeks and lips, improving overall facial aesthetics.', 'Wrinkle Smoothing: Targets fine lines and wrinkles, providing a smoother and more youthful complexion.', 'Facial Contouring: Enhances and defines facial features, contributing to a well-contoured and attractive look.', 'Non-Surgical Procedure: Offers a minimally invasive option with no need for surgery or extensive recovery time.', 'Immediate Results: Enjoy noticeable improvements right after your treatment, with gradual enhancements over time.'];
const myComponentList = myList.map((item, index) => (
  <li key={index}>{item}</li>
));

function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
          <MKTypography variant="h3" gutterBottom>
          Dermal Fillers Treatment: Enhance Your Facial Volume and Contours
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Dermal fillers are a cutting-edge solution for individuals looking to restore facial volume, smooth out wrinkles, and enhance their facial contours without undergoing surgery. These injectables are composed of various materials like hyaluronic acid, calcium hydroxylapatite, and poly-L-lactic acid, each offering distinct advantages tailored to your needs.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Why Choose Dermal Fillers?
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Dermal fillers provide a non-surgical method to achieve a youthful and refreshed appearance. They are ideal for treating areas such as the cheeks, lips, under-eyes, and jawline. The procedure is minimally invasive, requiring only a few injections with results that are visible immediately. The treatment helps replenish lost volume, smooth fine lines, and redefine facial contours, offering natural-looking results that can last several months.
            </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits of Dermal Fillers Treatment
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            {myComponentList}
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
