/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const myList = ['Brightens Complexion: Effectively reduces dark spots and hyperpigmentation, leading to a more radiant skin tone.', 'Evens Skin Tone: Improves uneven skin color and enhances overall skin radiance for a uniform appearance.', 'Advanced Technologies: Utilizes state-of-the-art treatments such as lasers and chemical peels for optimal results.', 'Safe and Effective: Dermatologist-approved methods ensure safety and minimal side effects.', 'Customized Solutions: Tailored treatments to address individual skin concerns and achieve desired outcomes.'];
const myComponentList = myList.map((item, index) => (
  <li key={index}>{item}</li>
));


function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
          <MKTypography variant="h3" gutterBottom>
          Skin Lightening & Whitening Treatment: Achieve Radiant and Even-Toned Skin
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Skin lightening and whitening treatments offer an effective solution for those seeking a brighter and more even-toned complexion. These advanced treatments target hyperpigmentation, dark spots, and uneven skin color to enhance overall skin radiance and uniformity.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Why Opt for Skin Lightening and Whitening?
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Our skin lightening and whitening treatments are designed to address various skin concerns such as hyperpigmentation and dark spots. By employing advanced technologies like laser therapy, chemical peels, and topical brightening agents, these treatments work to reduce melanin production and improve skin tone. With personalized solutions tailored to your specific needs, you can achieve a luminous and even complexion with minimal downtime.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits of Skin Lightening & Whitening Treatment
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            {myComponentList}
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
