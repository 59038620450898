/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/tattoo-removal-near-me.jpg";

const description = (
  <>
   Laser tattoo removal by Pico laser is a cutting-edge treatment designed to safely and effectively remove unwanted tattoos. Unlike traditional laser methods, Pico laser technology uses ultra-short pulses of energy to break down tattoo ink into smaller particles that are more easily absorbed and eliminated by the body. This advanced treatment works on all skin types and can target even the most stubborn ink colors, making it a top choice for those seeking to erase or fade tattoos with minimal discomfort and downtime.
  </>
);

function TattooRemoval() {
  return (
    <>
      <Helmet>
        <title>Laser Tattoo Removal by Pico Laser - Advanced Technology for Safe and Effective Results</title>
        <meta name="description" content="Experience the advanced technology of Pico laser for safe and effective tattoo removal. This cutting-edge treatment targets all ink colors and skin types with minimal discomfort and fast recovery. Achieve clear skin with Pico laser tattoo removal." />
        <meta name="keywords" content="Laser tattoo removal by Pico laser, Pico laser tattoo removal, Advanced tattoo removal technology, Safe and effective tattoo removal, Pico laser for all skin types, Minimal discomfort tattoo removal, Tattoo removal for all ink colors, Fast recovery tattoo removal" />
        <meta property="og:title" content="Laser Tattoo Removal by Pico Laser - Advanced Technology for Safe and Effective Results" />
        <meta property="og:description" content="Experience the advanced technology of Pico laser for safe and effective tattoo removal. This cutting-edge treatment targets all ink colors and skin types with minimal discomfort and fast recovery. Achieve clear skin with Pico laser tattoo removal." />
        <meta property="og:url" content="https://www.skinaamalviyanagar.com/treatments/pico-laser-tattoo-removal" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Laser Tattoo Removal by Pico Laser - Safe and Effective Solution
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default TattooRemoval;
