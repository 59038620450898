/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";


const myList = ['Natural Hair Growth Stimulation: PRP therapy uses your body’s own growth factors to naturally stimulate hair follicles and encourage regrowth.', 'Improves Hair Thickness: Regular PRP treatments can lead to noticeable improvements in hair thickness, making your hair look fuller and healthier.', 'Minimally Invasive: This non-surgical procedure is simple and quick, with minimal discomfort and no major recovery time needed.', 'Visible Results: Over a series of treatments, you’ll notice an increase in hair density, along with improvements in the overall quality and strength of your hair.'];
const myComponentList = myList.map((item, index) => (
  <li key={index}>{item}</li>
));

function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
          <MKTypography variant="h3" gutterBottom>
      PRP Treatment for Hair: Natural Hair Restoration and Growth Stimulation
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
Hair loss can be a challenging issue, but Platelet-Rich Plasma (PRP) Treatment offers a natural and effective solution. This innovative therapy harnesses the healing power of your own blood to stimulate hair follicles and promote hair growth. PRP treatment involves extracting a small amount of your blood, processing it to concentrate the platelets rich in growth factors, and then injecting this concentrate into the scalp. These growth factors work to rejuvenate hair follicles, leading to increased hair density and strength.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Why Choose PRP Treatment for Hair Loss?
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          PRP Treatment is ideal for individuals looking for a natural, non-surgical approach to hair restoration. Unlike other treatments, PRP leverages the body’s own healing mechanisms, ensuring that the process is safe and compatible with your biology. The treatment is minimally invasive, with no significant downtime, allowing you to quickly return to your daily activities while enjoying the benefits of improved hair growth.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits of PRP Treatment for Hair
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            {myComponentList}
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
