/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/dermarollerhair.jpeg";

const description = (
  <>
Platelet-Rich Plasma (PRP) Treatment for hair is a cutting-edge solution designed to stimulate natural hair growth and restore thinning hair. PRP therapy utilizes the patient’s own blood, which is processed to concentrate the platelets rich in growth factors. These growth factors, when injected into the scalp, stimulate hair follicles, promoting hair regrowth and improving hair thickness and strength. This minimally invasive procedure is ideal for individuals seeking a natural and effective way to combat hair loss and enhance hair density.
  </>
);

function DermarollerHair() {
  return (
    <>
      <Helmet>
      <title>PRP Treatment for Hair - Natural Hair Restoration and Growth Stimulation</title>
        <meta name="description" content="Discover PRP Treatment for hair, a natural solution that stimulates hair growth and restores thinning hair. Using your own growth factors, PRP enhances hair density and strength for visible, lasting results." />
        <meta name="keywords" content="PRP treatment for hair loss, Platelet-Rich Plasma therapy for hair, Natural hair regrowth treatment, Hair restoration with PRP, Stimulate hair follicles naturally, Non-surgical hair loss treatment, PRP for hair density improvement, Effective hair loss solution" />
        <meta property="og:title" content="PRP Treatment for Hair - Natural Hair Restoration and Growth Stimulation" />
        <meta property="og:description" content="Discover PRP Treatment for hair, a natural solution that stimulates hair growth and restores thinning hair. Using your own growth factors, PRP enhances hair density and strength for visible, lasting results." />
        <meta property="og:url" content="https://www.skinaamalviyanagar.com/treatments/prp-treatment-for-hair" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              PRP Treatment for Hair - Natural Hair Restoration and Growth Stimulation
              </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default DermarollerHair;
