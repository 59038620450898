import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import { Helmet } from "react-helmet";

// Home page section
import Information from "pages/Home/sections/Information";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg4.jpg";

function Home() {
  return (
    <>
      <Helmet>
              <title>Best Dermatologist in Jaipur | Skin Care Experts</title>
              <meta name="description" content="Looking for the best dermatologist in Jaipur? Our skin care experts offer advanced treatments for acne, scars, pigmentation, and more. Book your consultation today!" />
              <meta name="keywords" content="Best dermatologist in Jaipur, skin care expert Jaipur, acne treatment Jaipur, scar removal Jaipur, pigmentation treatment Jaipur, skin specialist Jaipur" />
              <meta property="og:title" content="Best Dermatologist in Jaipur | Skin Care Experts" />
              <meta property="og:description" content="Discover the top dermatologist in Jaipur offering expert care for all your skin needs. From acne treatment to pigmentation correction, our clinic provides the best solutions." />
              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://www.skinaamalviyanagar.com/" />
      </Helmet>
      <DefaultNavbar routes={routes} sticky action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Find Most Trusted and Verified Dermatologist
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Your Journey to Radiant Skin and Healthy Hair Begins Here.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
