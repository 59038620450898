/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";


const FAQ = () => {
  
const Que = ['How Do I Prepare for the Botox Treatment?', 'How Much the Treatment Costs?', 'Is This Treatment Safe?', 'How Long the Effects of Botox-Treatment for Hyperhidrosis Last?'];
const Ans = ['Normally, the patient doesn’t need to take a lot of precautions before the treatment. However, your dermatologist can advise you not to shave your armpits at least 3 days prior to the procedure. He may also ask you to stop taking any blood thinner for a few days.', 'The cost of Botox treatment varies from patient to patient. However, this treatment is very affordable at Skinaa Clinic.', 'Yes, it is completely safe. Botox is also used in the treatment of many other medical and aesthetic problems. And it is gaining more and more popularity over the years.', 'The effect of this treatment lasts for 6 to 12 months depending on the individual.'];

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h4" color="white">
            FAQs (Hyperhidrosis Treatment by Botox)
            </MKTypography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
          {Que.map((Que, index) => (
          <Stack key={index}>
            <MKBox  display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                <MKTypography variant="h4" color="text" pl={2}>
                  {Que}
                  </MKTypography>
            </MKBox>
              <MKTypography variant="body" color="text" pl={2}>
                {Ans[index]}
              </MKTypography>
          </Stack>
      ))} 
        </Grid>
      </Container>
    </MKBox>
)};
export default FAQ;
