/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/hyperhydrosis.jpg";

const description = (
  <>
   Botox treatment is a highly effective solution for individuals suffering from hyperhidrosis, a condition characterized by excessive sweating. This FDA-approved treatment involves injecting Botox into the affected areas, such as the underarms, palms, or feet, to block the nerves responsible for sweat production. The result is significantly reduced sweating and improved comfort in everyday life.
  </>
);

function HyperHydrosis() {
  return (
    <>
      <Helmet>
        <title>Botox Treatment for Excessive Sweating (Hyperhidrosis) - Effective, Safe, and Long-Lasting</title>
        <meta name="description" content="Discover effective relief from excessive sweating with Botox treatment. This FDA-approved, minimally invasive procedure provides long-lasting reduction in sweat production, helping you stay comfortable and confident." />
        <meta name="keywords" content="Botox treatment for hyperhidrosis, Excessive sweating treatment with Botox, Hyperhidrosis Botox injections, FDA-approved treatment for excessive sweating, Long-lasting relief from hyperhidrosis, Safe Botox treatment for sweating, Underarm sweating Botox treatment, Non-surgical hyperhidrosis treatment" />
        <meta property="og:title" content="Botox Treatment for Excessive Sweating (Hyperhidrosis) - Effective, Safe, and Long-Lasting" />
        <meta property="og:description" content="Discover effective relief from excessive sweating with Botox treatment. This FDA-approved, minimally invasive procedure provides long-lasting reduction in sweat production, helping you stay comfortable and confident." />
        <meta property="og:url" content="https://www.skinaamalviyanagar.com/treatments/hyperhidrosis-botox-treatment" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Botox Treatment for Excessive Sweating (Hyperhidrosis) - Effective, Safe, and Long-Lasting Relief
              </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default HyperHydrosis;
