/* eslint-disable import/no-anonymous-default-export */
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logo from "assets/images/logo.png";
import Instagram from "@mui/icons-material/Instagram";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Skinaa Malviya Nagar",
    image: logo,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/skinaamalviyanagar",
    },
    {
      icon: <Instagram />,
      link: "https://www.instagram.com/skinaa.malviyanagar/",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/@SkinaaMalviyaNagar",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "About us", href: "#" },
        { name: "Treatments", href: "#" },
        { name: "Contact us", href: "#" },
        { name: "blog", href: "/" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Skinaa Malviya Nagar{" "}
      <MKTypography
        component="a"
        href="/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Skinaa Malviya Nagar
      </MKTypography>
      .
    </MKTypography>
  ),
};
