/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/skinaa-mn.jpeg";
import bgBack from "assets/images/skinaa-mn.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Skinaa Clinic
                    <br />
                    Malviya Nagar
                  </>
                }
                description="Expert Skin and Hair Care Clinic: Personalized Treatments for Radiant Skin and Healthy Hair."
              />
              <RotatingCardBack
                image={bgBack}
                title="Discover Services"
                description="Transform Your Look with Our Advanced Skin and Hair Treatments."
                action={{
                  type: "internal",
                  route: "/contact-us",
                  label: "start with services",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Advanced Skin Treatments"
                  description="We offer cutting-edge solutions for all skin types, from acne and pigmentation treatments to anti-aging and rejuvenation therapies, using the latest technology for visible results."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="Personalized Hair Care"
                  description="Our customized hair care plans address issues like hair loss, thinning, and scalp health, ensuring your hair looks its best with tailored treatments and expert guidance."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="Experienced Specialists"
                  description="Our team of dermatologists and hair experts brings years of experience and specialized knowledge, providing you with the highest standard of care."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="State-of-the-Art Facility"
                  description="Our clinic is equipped with the latest technology and a serene environment, ensuring a comfortable and effective treatment experience from start to finish."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
