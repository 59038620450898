/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function FAQ() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h4" color="white">
            FAQs (LHR)
            </MKTypography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
          <Stack>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                  What is Laser Hair Removal Treatment Cost in Jaipur? Will My Hair Be Removed Permanently?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                The cost depends on the area on which treatment is needed. For example, laser hair removal cost will be lower for beard area than the chest area. The permanent hair removal treatment is also known as permanent hair reduction. That means the reduction (the density & the thickness) of hair will be permanent. For example, we all have facial hair but they are so thin and light that we don’t see them.
                </MKTypography>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                  Are There Any Side Effects of Laser Hair Removal?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                No there are no side effects of this treatment. Yes, you might experience a little pain during the LHR procedure but that will be minimal.
                </MKTypography>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                  How Many LHR Patients have You Treated So Far?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                We have treated more than 1000 LHR patients.
                </MKTypography>
          </Stack>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FAQ;
