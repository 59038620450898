/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/botox.jpg";

const description = (
  <>
Botox Anti-Ageing Treatment is a popular non-surgical procedure designed to smooth out wrinkles and fine lines, providing a youthful and refreshed appearance. Botox, a purified form of botulinum toxin, works by temporarily relaxing the muscles responsible for facial expressions, which reduces the appearance of dynamic wrinkles caused by repetitive muscle movements. This treatment is highly effective for addressing common signs of aging, such as crow’s feet, frown lines, and forehead wrinkles, making it a preferred choice for those seeking a quick and minimally invasive rejuvenation option.
  </>
);

function Botox() {
  return (
    <>
      <Helmet>
      <title>Botox Anti-Ageing Treatment - Rejuvenate Your Skin with a Non-Surgical Solution</title>
        <meta name="description" content="Experience the benefits of Botox Anti-Ageing Treatment for smooth, youthful skin. This non-surgical solution effectively reduces wrinkles and fine lines, providing long-lasting rejuvenation with minimal downtime." />
        <meta name="keywords" content="Botox anti-ageing treatment, Wrinkle reduction with Botox, Non-surgical anti-ageing solution, Botox for fine lines and wrinkles, Youthful skin rejuvenation, Quick wrinkle treatment, Botox cosmetic procedure, Effective anti-ageing treatment" />
        <meta property="og:title" content="Botox Anti-Ageing Treatment - Rejuvenate Your Skin with a Non-Surgical Solution" />
        <meta property="og:description" content="Experience the benefits of Botox Anti-Ageing Treatment for smooth, youthful skin. This non-surgical solution effectively reduces wrinkles and fine lines, providing long-lasting rejuvenation with minimal downtime." />
        <meta property="og:url" content="https://www.skinaamalviyanagar.com/treatments/botox-anti-ageing-treatment" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
             Botox Anti-Ageing Treatment - Rejuvenate Your Skin with a Non-Surgical Solution
              </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Botox;
