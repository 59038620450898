/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function FAQ() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h4" color="white">
            FAQs (Laser Toning for Hyper Pigmentation)
            </MKTypography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
          <Stack>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                    Can Laser toning help in removing premature aging signs?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                  Ans: Yes, it works perfectly to remove aging signs.
                </MKTypography>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                    How many sittings are required for laser toning?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                    Ans: Laser toning requires repeated sessions which varies depending on condition being treated . However an average of 4 to 6 sessions are required to improve tone , texture , darkness and spots on your face. Sessions are repeated at an interval of 2 to 4 wks..
                </MKTypography>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                    How much time each sitting takes for laser toning?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                  Ans: One session of laser toning will take about 30- 45 minutes.
                </MKTypography>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                  How much does Laser toning cost?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                  Ans: Laser toning cost varies according to body area being treated. Although approximate charges for face is around 3000.
                </MKTypography>
                <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                  Can a pregnant mother take a Laser toning procedure?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                  Ans: If you are pregnant or breastfeeding, then this procedure is not suitable for you.
                </MKTypography>
                <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                  On which skin types does it work best?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                  Ans: The laser toning procedure works on all skin types. So, you can take the treatment without any worries.
                </MKTypography>
                <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                  Where can I find the best skin clinic for a laser toning procedure near me?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                  Ans: Skinaa clinic is Jaipur's best skin clinic where you can find advanced equipment and expert dermatologists for laser toning procedures.
                </MKTypography>
                <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                  What would be the aftercare instruction I should keep in mind?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                  Ans: You would need to avoid sun exposure and always apply sunscreen when going out. Apart from that, use the irritation-free ointments our dermatologists prescribe.
                </MKTypography>
          </Stack>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FAQ;
