/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const myList = ['Effective Wrinkle Reduction: Botox targets and smooths out fine lines and wrinkles, helping you achieve a more youthful appearance.', 'Non-Surgical Treatment: The procedure is quick and non-invasive, with no need for recovery time or major downtime.', 'Quick Procedure: Sessions typically take just 15-30 minutes, making it easy to fit into your schedule with minimal discomfort.', 'Long-Lasting Results: Botox effects can last between 3 to 6 months, with routine treatments helping to maintain your rejuvenated look.'];
const myComponentList = myList.map((item, index) => (
  <li key={index}>{item}</li>
));




function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
          <MKTypography variant="h3" gutterBottom>
          Botox Anti-Ageing Treatment: Rejuvenate Your Skin with a Non-Surgical Solution
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          As we age, wrinkles and fine lines become more apparent, affecting our overall appearance and confidence. Botox Anti-Ageing Treatment offers an effective, non-surgical solution to combat these signs of aging. This innovative treatment uses a purified form of botulinum toxin to temporarily relax the muscles responsible for causing dynamic wrinkles, such as crow’s feet, frown lines, and forehead lines.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Why Choose Botox for Anti-Ageing?
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Botox is a popular choice for those seeking a quick and effective way to rejuvenate their skin. The procedure is minimally invasive, requiring no surgery and involving only a few tiny injections. The results are visible within days, with a smoother, more youthful appearance that can last for several months. Botox is ideal for individuals looking for a non-surgical anti-ageing solution that provides immediate and lasting results.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits of Botox Anti-Ageing Treatment
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            {myComponentList}
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
