/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/Skin-Whitening-Treatment.jpg";

const description = (
  <>
Skin lightening and whitening treatments are designed to improve skin tone and reduce the appearance of hyperpigmentation, dark spots, and uneven skin color. These treatments utilize advanced technologies and ingredients to brighten the complexion, enhance radiance, and promote a more uniform skin tone. Commonly used methods include chemical peels, laser treatments, and topical creams that target melanin production and skin discoloration. Achieve a luminous and even-toned complexion with our specialized skin lightening and whitening solutions.
  </>
);

function SkinLight() {
  return (
    <>
      <Helmet>
        <title>Skin Lightening & Whitening Treatment - Achieve Radiant and Even-Toned Skin</title>
        <meta name="description" content="Transform your complexion with our Skin Lightening & Whitening Treatment. Reduce hyperpigmentation, dark spots, and uneven skin tone with advanced solutions for a brighter and more radiant appearance." />
        <meta name="keywords" content="Skin lightening treatment, Skin whitening treatment, Hyperpigmentation solutions, Brightening skin therapy, Even skin tone treatment, Dark spot reduction, Radiant skin solutions, Melanin reduction treatments" />
        <meta property="og:title" content="Skin Lightening & Whitening Treatment - Achieve Radiant and Even-Toned Skin" />
        <meta property="og:description" content="Transform your complexion with our Skin Lightening & Whitening Treatment. Reduce hyperpigmentation, dark spots, and uneven skin tone with advanced solutions for a brighter and more radiant appearance." />
        <meta property="og:url" content="https://www.skinaamalviyanagar.com/treatments/skin-lightening-whitening-treatment" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
            Skin Lightening & Whitening Treatment - Achieve Radiant and Even-Toned Skin
              </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default SkinLight;
