/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const myList = ['Natural-Looking Hair Restoration: FUE allows for the precise placement of individual hair follicles, creating a natural hairline that blends seamlessly with existing hair.', 'Minimally Invasive Procedure: With no need for stitches, FUE minimizes discomfort and promotes quicker healing, reducing the overall recovery time.', 'Permanent Results: FUE offers a lasting solution to hair loss, with transplanted hair growing naturally for years to come.', 'Minimal Scarring: The FUE technique results in tiny, dot-like scars that are virtually undetectable and heal quickly, making it a preferred choice for those concerned about visible scarring.'];
const myComponentList = myList.map((item, index) => (
  <li key={index}>{item}</li>
));

function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
          <MKTypography variant="h3" gutterBottom>
          Best FUE Hair Transplant Treatment: Precision, Natural Results, and Minimal Scarring
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          The Follicular Unit Extraction (FUE) technique is widely regarded as the best hair transplant method available today. This advanced procedure focuses on providing natural hair transplant results with minimal scarring and downtime. The FUE method involves the precise extraction of individual hair follicles from the donor area, typically the back or sides of the head, and implanting them into the areas experiencing hair loss.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Why Choose FUE for Hair Restoration?
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          FUE is ideal for those seeking a permanent hair restoration solution with a natural look. Unlike older hair transplant methods, FUE leaves no linear scars, making it a popular choice for individuals who prefer to keep their hair short. The minimally invasive nature of the procedure also means a faster recovery time, allowing patients to return to their daily activities sooner.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits of FUE Hair Transplant Treatment
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            {myComponentList}
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
