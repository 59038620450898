/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
          <MKTypography variant="h1" gutterBottom>
            Looking for a treatment to get back healthy, glowing, flawless skin?
          </MKTypography>
          <MKTypography variant="body1" paragraph>
            Aging, pollution, sun exposure, excessive chemical use, and hormonal changes contribute to pigmentation and significant skin damage. If you are also suffering from it, then you must take laser toning at Skinaa clinic.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
            Important Pre-Treatment Tips
          </MKTypography>
          <MKTypography variant="body1" paragraph>
            A few things you must keep in mind when you seek laser toning include avoiding scrubbing, scratching, chemical peels, and exfoliators 1 week before and after treatment. Keep your skin hydrated with a good moisturizer.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
            Who Is a Good Candidate for Laser Toning?
          </MKTypography>
          <MKTypography variant="body1" paragraph>
            Skin problems are a common cause of discomfort and concern amongst people of all ages, especially the younger generation. With the advancement of technology and development in science, laser toning now makes it possible to get smooth and radiant skin.
          </MKTypography>
          <MKTypography variant="body1" paragraph>
            These are the candidates who are perfect for laser toning:
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            <li>Any individual who suffers from acne, wrinkles, tanned or sagging skin, pigmentation, age spots, and large pores can take laser toning.</li>
            <li>People who want to get their birthmarks, freckles, melasma, etc., treated.</li>
            <li>Individuals with skin blemishes, like liver spots, can take the treatment of laser toning.</li>
          </MKTypography>
          <MKTypography variant="h4" gutterBottom>
            After Care Tips/Precautions
          </MKTypography>
          <MKTypography variant="body1" paragraph>
            Laser toning is a very safe and effective procedure. However, a few patients may develop mild redness and burning sensation which resolves in a few hours and rarely for 2 to 3 days. After the laser toning, these are the things you must take care of:
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            <li>Sun protection</li>
            <li>Clean the area twice gently. Remember, do not rub.</li>
            <li>Do not use any other chemicals.</li>
            <li>Use laser soothing or repair cream to help the skin heal faster.</li>
            <li>Keep the skin hydrated with chemical-free creams or lotions.</li>
            <li>Use cleansers with normal pH to keep the area clean.</li>
            <li>Avoid facials, clean-up, or bleach in the treated area.</li>
          </MKTypography>
          <MKTypography variant="h4" gutterBottom>
            Benefits of Laser Toning
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            <li>Gives a clean and smooth skin.</li>
            <li>Laser toning suits all skin types.</li>
            <li>It is a rapid and smooth method that gives immediate results.</li>
            <li>Effective, painless, less time-consuming, and secure process.</li>
            <li>Triggers collagen production to restore even tone and texture of the skin.</li>
            <li>Makes the skin appear brighter and rejuvenated.</li>
            <li>Treats open pores and other skin problems without adverse effects.</li>
          </MKTypography>
          <MKTypography variant="h4" gutterBottom>
            What Results Can I Expect from Laser Toning?
          </MKTypography>
          <MKTypography variant="body1" paragraph>
            Laser toning is a very advanced technology and gives excellent results, including:
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            <li>Bright skin with improved inflammatory pigmentation.</li>
            <li>Lightening of pigmented areas and lesions in the skin.</li>
            <li>Improvement in texture, elasticity, and radiance of your skin due to collagen remodeling.</li>
            <li>Helps to improve fine lines, aging lines, open pores, and wrinkles.</li>
            <li>Long-lasting results.</li>
          </MKTypography>

          <MKTypography variant="h4" gutterBottom>
            What Skin Problems Can Be Treated with the Laser Toning Treatment?
          </MKTypography>
          <MKTypography variant="body1" paragraph>
            With laser toning, you can treat the following skin problems:
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            <li>Melasma</li>
            <li>Acne</li>
            <li>Scars</li>
            <li>Sun Spots</li>
            <li>Skin Pigmentation</li>
          </MKTypography>

          <MKTypography variant="h4" gutterBottom>
            Ready for Your Laser Toning Session?
          </MKTypography>
          <MKTypography variant="body1" paragraph>
            Are you planning to get a laser toning session? Contact +91-9649219219 or care@skinaa.com to book a consultation and clarify all your doubts. At Skinaa, we examine your skin, discuss different treatment options available for you, and suggest the best suitable option depending on your specific skin concerns. If you wish to experience an amazing transformation of your skin with Laser Toning treatment, visit us!
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
