/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/meso.jpg";

const description = (
  <>
    Experience the transformative power of mesotherapy at Skinaa Clinic Malviya Nagar. Mesotherapy is a minimally invasive procedure that delivers vital nutrients, vitamins, and medications directly into the mesoderm, the middle layer of your skin. This innovative treatment helps rejuvenate, hydrate, and tighten your skin, addressing a range of skin concerns such as fine lines, wrinkles, cellulite, and uneven skin tone.
  </>
);

function mesoTherapy() {
  return (
    <>
      <Helmet>
        <title>Mesotherapy - Rejuvenate Your Skin with Targeted Treatments</title>
        <meta name="description" content="Experience the benefits of mesotherapy at Skinaa Clinic Malviya Nagar. This innovative treatment revitalizes and hydrates your skin, addressing fine lines, wrinkles, and uneven tone with minimal downtime." />
        <meta name="keywords" content="mesotherapy, skin rejuvenation, hydration treatment, anti-aging, cellulite treatment, uneven skin tone, youthful skin, skin hydration" />
        <meta property="og:title" content="Mesotherapy: Rejuvenate Your Skin with Targeted Treatments" />
        <meta property="og:description" content="Discover mesotherapy at Skinaa Clinic Malviya Nagar. Our expert treatment revitalizes and hydrates the skin, addressing aging signs, pigmentation, and cellulite with minimal downtime." />
        <meta property="og:image" content="https://example.com/mesotherapy-image.jpg" />
        <meta property="og:url" content="https://skinaamalviyanagar.com/treatments/mesotherapy" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Mesotherapy: Rejuvenate Your Skin with Targeted Treatments
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default mesoTherapy;
