/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function FAQ() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h4" color="white">
            FAQs (Microdermabrasion for Hyper Pigmentation)
            </MKTypography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
          <Stack>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                  On which part of the body Microdermabrasion treatment can be performed?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                Ans: It can be done on buttocks, thighs, face, neck, jawline, forehead, cheeks, and waistline. It is a very safe procedure.
                </MKTypography>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                  What are the benefits of Microdermabrasion Treatment?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                Ans: With Microdermabrasion treatment, you can get your wrinkles, stretch marks, scars (if any), melasma, and uneven skin tones treated.
                </MKTypography>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                  Is this treatment beneficial in blackheads and enlarged pores?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                Ans: Yes, to get rid of blackheads and enlarged pores, theres no better treatment than Skinaas microdermabrasion.
                </MKTypography>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                  What is the cost of microdermabrasion treatment in Jaipur?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                Ans: At Skinaa Clinic, the microdermabrasion treatment is provided at a very affordable cost. However, the total cost will depend on the area which needs to be treated. A dermatologist will first check your condition and suggest the treatment appropriately.
                </MKTypography>
          </Stack>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FAQ;
