/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const myList = ['Precision: The use of laser technology allows for precise targeting of the mole, ensuring minimal impact on surrounding tissue. This precision helps to reduce the risk of scarring and promotes a smoother healing process.', 'Safe for All Skin Types: Laser mole removal is safe for individuals with different skin types. The laser settings can be customized to ensure optimal effectiveness and safety, making it a versatile treatment option.', 'Quick Procedure: The laser mole removal procedure is typically quick, often taking only a few minutes per mole. Patients experience minimal discomfort, and the treatment can be completed in a single visit.', 'Minimally Invasive: As a non-surgical treatment, laser mole removal minimizes the risks associated with traditional mole excision, such as infection and scarring. Patients can enjoy a quicker recovery and return to their normal activities shortly after the procedure.'];
const myComponentList = myList.map((item, index) => (
  <li key={index}>{item}</li>
));
  
function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
      <MKTypography variant="h3" gutterBottom>
          Laser Mole Removal Treatment: Safe and Precise
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Laser mole removal is an advanced treatment designed to safely and precisely remove unwanted moles from the skin. Whether for cosmetic reasons or due to concerns about the mole's appearance, laser technology provides a non-invasive solution that minimizes discomfort and scarring. Unlike traditional surgical methods, laser mole removal targets only the mole, leaving the surrounding skin unharmed.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Why Choose Laser Mole Removal?
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Laser mole removal is an ideal option for those seeking a quick and effective solution for mole removal. The procedure uses focused laser energy to break down the pigment within the mole, which is then naturally absorbed by the body. This method is particularly beneficial for moles that are flat or slightly raised and is suitable for all skin types.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits of Laser Mole Removal
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            {myComponentList}
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
