// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Featuring() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h3" my={1}>
              What is the Process of Chemical Peel Treatment?
            </MKTypography>
            <MKTypography variant="body" color="text" mb={2}>
            Considering the amazing effects of the chemical peel treatment at Skinaa Clinic Jaipur, its procedure is reasonably very simple. First, the select peel is applied on the part of the skin affected by the hyperpigmentation problem. The chemical peel causes the dead skin to blister and to leave the skin surface gradually. The new skin is usually very smooth, soft, and without too many wrinkles.
            </MKTypography>
            <MKTypography
              component="a"
              href="#"
              variant="body2"
              color="info"
              fontWeight="regular"
              sx={{
                width: "max-content",
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: "translateX(3px)",
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: "translateX(6px)",
                },
              }}
            >
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">mediation</Icon>
                </MKBox>
                <MKTypography variant="h3" color="text" pl={2}>
                  Effective
                </MKTypography>
              </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                The process removes the dead skin to reveal new, youthful skin.
                </MKTypography>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">settings_overscan</Icon>
                </MKBox>
                <MKTypography variant="h3" color="text" pl={2}>
                  Improved Look
                </MKTypography>
              </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  It improves the skin texture, lending it a bright and spotless look.
                </MKTypography>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">token</Icon>
                </MKBox>
                <MKTypography variant="h3" color="text" pl={2}>
                  Quick
                </MKTypography>
              </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  The process lasts no more than 30 minutes to an hour.
                </MKTypography>
                <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">token</Icon>
                </MKBox>
                <MKTypography variant="h3" color="text" pl={2}>
                  Cost-Effective
                </MKTypography>
              </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  The chemical peel treatment at Skinaa is very affordable.
                </MKTypography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Featuring;