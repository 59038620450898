/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Featuring from "./sections/Featuring";
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/lasertoning.jpg";

const description = (
  <>
    Laser toning is a cutting-edge skincare procedure designed to address various skin concerns such as pigmentation, acne scars, and uneven skin tone. This non-invasive treatment uses advanced laser technology to stimulate collagen production and improve skin texture, resulting in a smoother, more youthful complexion.
  </>
);
function laserToning() {
  return (
    <>
      <Helmet>
        <title>Laser Toning - Revitalize Your Skin</title>
        <meta name="description" content="Unlock the potential of laser toning at Skinaa Clinic Malviya Nagar. Our treatments address pigmentation, sun damage, acne scars, and uneven skin tone for radiant, youthful skin." />
        <meta name="keywords" content= "Laser Toning Treatment, Skin Concerns, Pigmentation, Acne Scars, Uneven Skin Tone, Non-Invasive, Treatment, Collagen Production, Skin Texture, Radiance, Age Spots, Large Pores, Sun Damage, Hyperpigmentation, Fine Lines and Wrinkles, Expert Dermatologists, Safe and Effective Treatments" />
        <meta property="og:title" content="Laser Toning: Revitalize Your Skin" />
        <meta property="og:description" content="Experience the benefits of laser toning at Skinaa Clinic Malviya Nagar. Our advanced treatments brighten skin, smooth texture, and boost collagen for a rejuvenated complexion." />
        <meta property="og:image" content="https://example.com/image.jpg" />
        <meta property="og:url" content="https://skinaamalviyanagar.com/treatments/laser-toning" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Laser Toning Treatment: Achieve Radiant, Even-Toned Skin
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      {/* <Information /> */}
      <Featuring />
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default laserToning;
