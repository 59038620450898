/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const myList = ['Advanced Acne Treatment: The Fractional CO2 laser targets the root causes of acne and scars, providing deep and lasting rejuvenation. It’s especially effective for those with stubborn or deep acne scars.', 'Stimulates Collagen Production: The laser’s controlled micro-injuries encourage the skin to produce collagen, a vital protein that helps maintain skin’s firmness and smoothness. Increased collagen production leads to improved skin texture and reduced scar visibility.', 'Effective Scar Reduction: This treatment is particularly beneficial for those with deep or pitted acne scars. The laser significantly reduces the appearance of scars, helping to restore a smoother skin surface.', 'Minimal Downtime: Despite the powerful results, the recovery time after a Fractional CO2 laser treatment is relatively short. Most patients can return to their regular activities within a few days, making it a convenient option for those with busy schedules.'];
const myComponentList = myList.map((item, index) => (
  <li key={index}>{item}</li>
));

function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
          <MKTypography variant="h3" gutterBottom>
          Fractional CO2 Laser for Acne: Advanced Treatment for Clearer, Smoother Skin
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Acne and acne scars can be challenging to manage, often affecting self-confidence. The Fractional CO2 laser offers a powerful and advanced solution to these concerns. By delivering precise laser energy to the skin, this treatment creates tiny, controlled injuries that stimulate the body’s natural healing process. As the skin heals, it produces new collagen, which helps to smooth out scars and improve overall skin texture.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Why Choose Fractional CO2 Laser for Acne?
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Fractional CO2 laser treatment is highly effective in targeting and reducing the appearance of acne scars. This treatment not only addresses surface-level concerns but also penetrates deeper layers of the skin to promote long-term rejuvenation. The result is a clearer, smoother complexion with improved texture and tone.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits of Fractional CO2 Laser for Acne
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            {myComponentList}
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
