/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const myList = ['Comprehensive Hair Loss Solutions: Our treatments are tailored to address the unique hair loss patterns of both men and women, ensuring optimal results.', 'Medical and Non-Surgical Options: Choose from FDA-approved medications, PRP Therapy, and other minimally invasive procedures designed to promote hair regrowth.', 'Personalized Treatment Plans: Each treatment plan is customized to meet your specific needs, ensuring that you receive the most effective care possible.', 'Long-Lasting Results: Our hair loss treatments not only promote new hair growth but also help prevent further hair loss, providing sustainable results.'];
const myComponentList = myList.map((item, index) => (
  <li key={index}>{item}</li>
));

function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
          <MKTypography variant="h3" gutterBottom>
          Men & Women Hair Loss Treatment: Effective Solutions for All Types of Hair Loss
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Hair loss is a common concern for both men and women, with various factors such as genetics, stress, hormonal changes, and aging contributing to the problem. Fortunately, there are numerous effective treatments available to address hair loss and help you regain your confidence.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Why Choose Specialized Hair Loss Treatment?
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Whether you're experiencing thinning hair, a receding hairline, or bald patches, specialized hair loss treatments offer tailored solutions for men and women. These treatments range from medical options like Minoxidil and Finasteride, which are FDA-approved and proven to be effective, to advanced procedures like PRP Therapy and Hair Transplantation, which provide lasting results.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits of Men & Women Hair Loss Treatment
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            {myComponentList}
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
