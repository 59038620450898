/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const myList = ['Effective Sweat Reduction: Botox works by targeting the nerves responsible for excessive sweating, effectively reducing sweat production in treated areas. This results in significant improvement in comfort and confidence.', 'FDA-Approved and Safe: Botox for hyperhidrosis is an FDA-approved treatment, ensuring that it meets stringent safety and efficacy standards. The procedure is performed by experienced professionals, guaranteeing optimal results.', 'Long-Lasting Relief: One of the most appealing aspects of Botox treatment for hyperhidrosis is its long-lasting effects. Patients typically enjoy reduced sweating for several months, making it a convenient solution for managing hyperhidrosis.', 'Minimally Invasive Procedure: Botox injections are minimally invasive, requiring no surgery or significant downtime. Patients can resume their normal activities almost immediately after treatment, making it a convenient option for those with busy lifestyles.'];
const myComponentList = myList.map((item, index) => (
  <li key={index}>{item}</li>
));

function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
          <MKTypography variant="h3" gutterBottom>
          Botox Treatment for Excessive Sweating (Hyperhidrosis): Safe, Effective, and Long-Lasting Relief
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Hyperhidrosis, or excessive sweating, can significantly impact one’s quality of life, causing discomfort and embarrassment. Botox treatment offers an effective solution for this condition. By injecting Botox into the areas prone to excessive sweating, such as the underarms, palms, or feet, the treatment blocks the nerve signals responsible for sweat production, leading to a dramatic reduction in sweating.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Why Choose Botox Treatment for Hyperhidrosis?
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Botox is an FDA-approved treatment that has been proven effective in reducing excessive sweating. This minimally invasive procedure is safe and performed by trained professionals, ensuring that patients receive the highest standard of care. The results of Botox treatment are long-lasting, with many patients experiencing relief from excessive sweating for up to 6-12 months.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits of Botox Treatment for Hyperhidrosis
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            {myComponentList}
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
