/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const myList = ['Precise Targeting: The use of focused laser energy in wart removal ensures that only the wart is targeted, leaving the surrounding skin unharmed. This precision helps to minimize scarring and leads to a more aesthetically pleasing result.', 'Effective for All Skin Types: Laser wart removal can be adjusted to accommodate different skin types, ensuring that the treatment is safe and effective for everyone. This adaptability makes it an excellent option for those with sensitive skin or darker skin tones.', 'Quick Recovery: One of the significant advantages of laser wart removal is the quick recovery time. Most patients experience minimal discomfort following the procedure and can return to their normal activities almost immediately.', 'Minimally Invasive: As a non-invasive treatment, laser wart removal reduces the risks associated with traditional surgical methods. There is no need for incisions or stitches, which significantly lowers the chances of infection and other complications.'];
const myComponentList = myList.map((item, index) => (
  <li key={index}>{item}</li>
));
  
function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
      <MKTypography variant="h3" gutterBottom>
          Laser Wart Removal Treatment: Effective and Safe
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Laser wart removal is a modern, effective treatment designed to safely eliminate warts using precise laser energy. Warts, often caused by the human papillomavirus (HPV), can be both a cosmetic concern and a source of discomfort. Unlike traditional methods, laser wart removal offers a targeted approach that focuses solely on the wart, minimizing damage to the surrounding skin and reducing the risk of scarring.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Why Choose Laser Wart Removal?
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          This treatment is particularly beneficial for individuals who have stubborn or recurring warts that have not responded to other removal methods. Laser technology allows for precise targeting of the wart, ensuring that the treatment is both effective and minimally invasive. Moreover, laser wart removal is suitable for all skin types, making it a versatile option for many patients.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits of Laser Wart Removal
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            {myComponentList}
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
