/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits:
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            <li>Skin Rejuvenation: Reduces signs of aging and revitalizes skin for a youthful appearance.</li>
            <li>Hydration Boost: Deeply hydrates and nourishes the skin, enhancing overall texture and glow.</li>
            <li>Targeted Treatment: Customizable to address specific issues such as pigmentation, cellulite, and sagging skin.</li>
            <li>Minimal Downtime: Quick procedure with minimal recovery time, allowing you to resume daily activities promptly.</li>
          </MKTypography>
          <MKTypography variant="h4" paragraph>
          Rediscover youthful, glowing skin with our expert mesotherapy services. Contact us today at +91-7300009731 to schedule a consultation and explore how mesotherapy can enhance your skin’s health and appearance.
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
