/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const myList = ['Targeted Therapy: The Excimer Laser focuses its UVB light precisely on the white spots, ensuring that the surrounding healthy skin remains unaffected. This precision reduces the risk of side effects and enhances the effectiveness of the treatment.', 'Safe and Non-Invasive: Excimer Laser therapy is a non-invasive treatment that is safe for all skin types. It involves minimal discomfort during the procedure and requires no recovery time, making it an ideal option for busy individuals.', 'Effective for Vitiligo: For patients with vitiligo, the Excimer Laser is particularly beneficial. It stimulates the repopulation of melanocytes in the depigmented areas, promoting natural pigment restoration and a more even skin tone.', 'Quick and Convenient: Treatment sessions with the Excimer Laser are typically short, often completed within minutes. This allows patients to undergo therapy with minimal disruption to their daily routines, making it a convenient option for those seeking quick results.'];
const myComponentList = myList.map((item, index) => (
  <li key={index}>{item}</li>
));
  
function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
      <MKTypography variant="h3" gutterBottom>
      Excimer Laser Treatment for White Spots: Safe and Targeted
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          The Excimer Laser is an advanced therapeutic option for individuals dealing with white spots on the skin, often a result of conditions like vitiligo. This state-of-the-art laser uses focused UVB light to stimulate melanocyte activity in the affected areas, promoting the natural repigmentation of the skin.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Why Choose Excimer Laser for White Spots?
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          The Excimer Laser offers a precise, targeted approach to treating white spots, ensuring that only the depigmented areas are exposed to the laser's therapeutic effects. This makes it a preferred option for those looking for an effective and minimally invasive treatment.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits of Excimer Laser Therapy
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            {myComponentList}
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
