/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/Microdermabrasion.jpg";

const description = (
  <>
   Discover the benefits of Hydrafacial at Skinaa Clinic Malviya Nagar, a non-invasive skin resurfacing treatment designed to rejuvenate and revitalize your complexion. Hydrafacial effectively removes dead skin cells, stimulates collagen production, and enhances skin texture, leaving you with a smoother, more radiant appearance.
  </>
);

function Hydrafacial() {
  return (
    <>
      <Helmet>
      <title>Hydrafacial - Reveal Smoother, Brighter Skin</title>
      <meta name="description" content="Experience the benefits of Hydrafacial at Skinaa Clinic Malviya Nagar. This advanced skin resurfacing treatment exfoliates dead skin cells, improves texture, and boosts collagen for a radiant, youthful complexion." />
      <meta name="keywords" content="hydrafacial, skin resurfacing, exfoliation, collagen boosting, skin renewal, acne scars, fine lines, radiant skin, youthful complexion" />
      <meta property="og:title" content="Hydrafacial: Reveal Smoother, Brighter Skin" />
      <meta property="og:description" content="Unlock radiant, youthful skin with hydrafacial at Skinaa Clinic Malviya Nagar. This non-invasive treatment exfoliates dead skin, improves texture, and enhances radiance for a glowing complexion." />
      <meta property="og:url" content="https://www.skinaamalviyanagar.com/treatments/hydrafacial" />
      <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Hydrafacial: Reveal Smoother, Brighter Skin
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Hydrafacial;
