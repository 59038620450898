/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/earlobe.jpg";

const description = (
  <>
   Laser earlobe repair is a minimally invasive procedure designed to correct and restore damaged or stretched earlobes. Whether due to heavy earrings, trauma, or gauging, earlobes can become stretched, torn, or misshapen over time. Laser technology offers a precise and effective solution for earlobe repair, with minimal scarring and a quicker recovery compared to traditional surgical methods. This advanced treatment is ideal for those looking to restore the natural appearance of their earlobes with minimal downtime.
  </>
);

function Earlobe() {
  return (
    <>
      <Helmet>
        <title>Laser Earlobe Repair Treatment - Safe, Effective, and Minimally Invasive</title>
        <meta name="description" content="Restore the natural appearance of your earlobes with minimally invasive laser earlobe repair. Safe for all skin types, this procedure offers effective results with minimal scarring and quick recovery. Achieve natural-looking earlobes with our advanced laser treatment." />
        <meta name="keywords" content="Laser earlobe repair, Earlobe repair treatment, Minimally invasive earlobe repair, Laser earlobe restoration, Safe earlobe repair procedure, Quick recovery earlobe repair, Effective earlobe repair with laser, Earlobe repair for stretched earlobes" />
        <meta property="og:title" content="Laser Earlobe Repair Treatment - Safe, Effective, and Minimally Invasive" />
        <meta property="og:description" content="Restore the natural appearance of your earlobes with minimally invasive laser earlobe repair. Safe for all skin types, this procedure offers effective results with minimal scarring and quick recovery. Achieve natural-looking earlobes with our advanced laser treatment." />
        <meta property="og:url" content="https://www.skinaamalviyanagar.com/treatments/laser-earlobe-repair" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Laser Earlobe Repair Treatment - Safe, Effective, and Minimally Invasive
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Earlobe;
