/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/dermafillers.jpg";

const description = (
  <>
Dermal fillers are a popular cosmetic treatment designed to restore volume, smooth wrinkles, and enhance facial contours. These injectable treatments are made from various materials, including hyaluronic acid, calcium hydroxylapatite, and poly-L-lactic acid, each providing unique benefits. Dermal fillers can address areas such as the cheeks, lips, under-eyes, and jawline, offering a youthful and refreshed appearance without the need for surgery. The results are immediate and can last for several months, making dermal fillers a versatile option for non-surgical facial rejuvenation.
  </>
);

function DermaFillers() {
  return (
    <>
      <Helmet>
      <title>Dermal Fillers Treatment - Enhance Your Facial Volume and Contours</title>
      <meta name="description" content="Discover the benefits of Dermal Fillers Treatment for facial volume enhancement and wrinkle smoothing. Achieve a youthful appearance with non-surgical injections that offer immediate and natural-looking results." />
      <meta name="keywords" content="Dermal fillers treatment, Facial volume enhancement, Non-surgical wrinkle reduction, Hyaluronic acid fillers, Facial contouring with fillers, Lip enhancement fillers, Cheek volume restoration, Wrinkle smoothing injections" />
      <meta property="og:title" content="Dermal Fillers Treatment - Enhance Your Facial Volume and Contours" />
      <meta property="og:description" content="Discover the benefits of Dermal Fillers Treatment for facial volume enhancement and wrinkle smoothing. Achieve a youthful appearance with non-surgical injections that offer immediate and natural-looking results." />
      <meta property="og:url" content="https://www.skinaamalviyanagar.com/treatments/dermal-fillers-treatment" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Dermal Fillers Treatment - Enhance Your Facial Volume and Contours
              </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default DermaFillers;
