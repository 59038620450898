/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const myList = ['Non-Invasive Skin Treatments: One of the major advantages of IPL is that it is a non-invasive treatment. Unlike more aggressive procedures, IPL requires no incisions or downtime, allowing you to resume your normal activities right after the session.',
  'IPL for Pigmentation: IPL is highly effective in treating pigmentation issues such as age spots, sunspots, and freckles. By targeting the melanin in these areas, IPL helps to reduce the appearance of dark spots, leading to a more even complexion.',
  'IPL for Rosacea: For those dealing with rosacea or facial redness, IPL offers a safe and effective treatment option. The light energy targets the blood vessels responsible for redness, helping to diminish their appearance over time.',
  'Safe and Effective IPL Treatment: Safety is paramount in any cosmetic procedure, and IPL is no exception. The treatment is designed to be safe for various skin types, with settings that can be adjusted to suit your specific needs. This customization ensures that you receive the most effective treatment with minimal risk.',
  'IPL for Age Spots: As we age, the skin can develop age spots due to prolonged sun exposure. IPL treatment is a proven method for reducing these spots, helping to restore a more youthful appearance.',
  'Long-Lasting Skin Rejuvenation Results: With a series of IPL treatments, you can achieve long-lasting improvements in your skin’s appearance. The results are gradual but enduring, giving you a refreshed and rejuvenated look that can last for months.'];
const myComponentList = myList.map((item, index) => (
  <li key={index}>{item}</li>
));
  
function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
      <MKTypography variant="h3" gutterBottom>
           Why Choose IPL for Skin Rejuvenation?
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          IPL skin rejuvenation works by delivering pulses of broad-spectrum light to the skin, targeting areas of discoloration, redness, and other imperfections. The light penetrates the skin, where it is absorbed by the pigment in sunspots, freckles, and other lesions. This process helps to break down the pigment, allowing the body to naturally remove it, resulting in a more even skin tone.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits of IPL Treatment
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            {myComponentList}
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
