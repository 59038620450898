/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
const myList = ['Precision Acne Scar Treatment: The Erbium Fiber Laser allows for targeted treatment of scar tissue, which minimizes damage to surrounding skin and promotes effective healing.', 'Stimulates Collagen Production: By stimulating collagen production, this treatment not only reduces the appearance of scars but also helps to maintain a youthful, firm complexion.', 'Minimal Downtime: This non-surgical procedure offers a quick recovery time, allowing you to return to your daily routine with minimal disruption.', 'Effective Skin Resurfacing: The Erbium Fiber Laser is highly effective in resurfacing the skin, reducing the visibility of acne scars and promoting a smoother skin texture.'];
const myComponentList = myList.map((item, index) => (
  <li key={index}>{item}</li>
));




function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
          <MKTypography variant="h3" gutterBottom>
          Acne Scar Treatment by Erbium Fiber Laser: Precision and Effective Skin Resurfacing
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Acne scars can significantly impact the appearance of your skin, but with the Erbium Fiber Laser, there’s a precise and effective solution available. This advanced laser technology targets the water content within the skin, allowing for the precise removal of scar tissue. The Erbium Fiber Laser not only resurfaces the skin but also stimulates the production of collagen, a vital component for maintaining skin’s elasticity and firmness.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Why Choose Erbium Fiber Laser for Acne Scar Treatment?
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          The Erbium Fiber Laser is known for its precision and effectiveness in treating acne scars. Unlike other treatments, it offers controlled removal of damaged skin layers, which promotes the growth of new, healthy skin. This laser is especially beneficial for those with deeper or more pronounced acne scars, as it can significantly reduce their appearance while improving overall skin texture and tone.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits of Erbium Fiber Laser for Acne Scars
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            {myComponentList}
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
