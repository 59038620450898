// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Featuring() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h3" my={1}>
            Benefits of Laser Toning:
            </MKTypography>
            <MKTypography variant="body" color="text" mb={2}>
            Laser toning offers a range of benefits for enhancing skin health and appearance. This advanced, non-invasive treatment harnesses the power of laser technology to address various skin concerns effectively. Here’s a detailed look at the key benefits of laser toning:
            </MKTypography>
            <MKTypography
              component="a"
              href="#"
              variant="body2"
              color="info"
              fontWeight="regular"
              sx={{
                width: "max-content",
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: "translateX(3px)",
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: "translateX(6px)",
                },
              }}
            >
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">mediation</Icon>
                </MKBox>
                <MKTypography variant="h3" color="text" pl={2}>
                Reduces Pigmentation:
                </MKTypography>
              </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                Effective in treating dark spots, melasma, and sun damage, helping to even out skin tone and brighten your appearance.
                </MKTypography>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">settings_overscan</Icon>
                </MKBox>
                <MKTypography variant="h3" color="text" pl={2}>
                Smoothens Skin Texture: 
                </MKTypography>
              </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                Minimizes the appearance of fine lines, wrinkles, and acne scars by promoting collagen regeneration.
                </MKTypography>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">token</Icon>
                </MKBox>
                <MKTypography variant="h3" color="text" pl={2}>
                Enhances Radiance:
                </MKTypography>
              </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                Restores a natural glow to your skin, making it look fresh and revitalized.
                </MKTypography>
                <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">token</Icon>
                </MKBox>
                <MKTypography variant="h3" color="text" pl={2}>
                Targets Multiple Skin Concerns:
                </MKTypography>
              </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                Ideal for individuals dealing with age spots, large pores, and overall skin dullness.
                </MKTypography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Featuring;