/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/diodelaser.jpg";

const description = (
  <>
   Diode laser hair removal is a state-of-the-art treatment designed for those seeking permanent hair reduction. Unlike traditional hair removal methods like shaving or waxing, which provide only temporary results, diode laser technology targets the hair follicles at their roots, offering a long-lasting solution to unwanted hair. This advanced method is effective on all skin types, making it a popular choice for individuals looking to achieve smooth, hair-free skin.
  </>
);

function DiodeLaser() {
  return (
    <>
      <Helmet>
        <title>Diode Laser Hair Removal - Advanced Technology for Smooth, Hair-Free Skin</title>
        <meta name="description" content="Discover the benefits of diode laser hair removal. Achieve long-lasting, smooth skin with our advanced laser technology, designed for all skin types. Safe, effective, and virtually pain-free." />
        <meta name="keywords" content="Diode laser hair removal, Permanent hair reduction, Laser hair removal for all skin types, Pain-free hair removal, Advanced laser technology for hair removal, Safe laser hair removal, Fast laser hair removal sessions, Long-lasting hair removal results" />
        <meta property="og:title" content="Diode Laser Hair Removal - Advanced Technology for Smooth, Hair-Free Skin" />
        <meta property="og:description" content="Discover the benefits of diode laser hair removal. Achieve long-lasting, smooth skin with our advanced laser technology, designed for all skin types. Safe, effective, and virtually pain-free." />
        <meta property="og:url" content="https://www.skinaamalviyanagar.com/treatments/diode-laser-hair-removal" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Diode Laser Hair Removal - Smooth Skin with Advanced Technology
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default DiodeLaser;
