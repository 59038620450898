/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function FAQ() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h4" color="white">
            FAQs (Chemical Peeling Treatment)
            </MKTypography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
          <Stack>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                  Which is the Best Skin Clinic Near Me for Chemical Peeling Treatment?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                Ans: Without a doubt, Skinaa Clinic is Jaipur's best chemical peeling skin clinic. We are located in Malviya Nagar.
                </MKTypography>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                  What Would be the cost of Chemical Peel Treatment?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                Ans: At Skinaa, the cost of chemical peel treatment is very reasonable. Starting from Rs. 1999, it depends on which part of the body you want the treatment. We have done thousands of successful peeling procedures for melasma so far.
                </MKTypography>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                  Which Kinds of Peels Do Skinaa Clinic Offers?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                Ans: We have a range of peels available including mandelic peel, glycolic peel, party peel, combination peel, and many more.
                </MKTypography>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                  <MKTypography variant="h4" color="text" pl={2}>
                  What Can I Treat through the Chemical Peel Treatment?
                  </MKTypography>
            </MKBox>
                <MKTypography variant="body" color="text" pl={2}>
                Ans: Chemical peel treatment is beneficial in many kinds of skin problems such as wrinkles, facial scarring, age spots, sun damage spots, lines around the mouth and under the eyes, dark patches, freckles, dark skin, and lines caused due to pregnancy.
                </MKTypography>
          </Stack>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FAQ;
