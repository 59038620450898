// @mui material components
import Icon from "@mui/material/Icon";

// Pages
import Home from "layouts/pages/home";
// import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import ChemicalPeel from "pages/LandingPages/Services/chemicalPeel";
import LaserToning from "pages/LandingPages/Services/laserToning";
import MesoTherapy from "pages/LandingPages/Services/mesoTherapy";
import Hydrafacial from "pages/LandingPages/Services/Microdermabrasion";
import CarbonLaserFacial from "pages/LandingPages/Services/CarbonLaserFacial";
import DiodeLaser from "pages/LandingPages/Services/diodeLHR";
import IPL from "pages/LandingPages/Services/IPLLHR copy";
import TattooRemoval from "pages/LandingPages/Services/tattooRemoval";
import Earlobe from "pages/LandingPages/Services/EarLobe";
import WartRemoval from "pages/LandingPages/Services/WartRemoval";
import MoleRemoval from "./pages/LandingPages/Services/MoleRemoval";
import BlisterGraphting from "pages/LandingPages/Services/BlisterGraphting";
import Excimer from "pages/LandingPages/Services/Excimer";
import MicroPigmentation from "pages/LandingPages/Services/MicroPigmentation";
import PunchGraphting from "pages/LandingPages/Services/PunchGraphting";
import HyperHydrosis from "pages/LandingPages/Services/HyperHydrosis";
import Co2Laser from "pages/LandingPages/Services/CO2Laser";
import AcnePRP from "pages/LandingPages/Services/AcnePRP";
import ErbiumFiber from "pages/LandingPages/Services/ErbiumFiber";
import DermarollerHair from "pages/LandingPages/Services/DermaRollerHair";
import HairTransplant from "pages/LandingPages/Services/FUEhair";
import HairLoss from "pages/LandingPages/Services/HairLoss";
import Botox from "pages/LandingPages/Services/Botox";
import DermaFillers from "pages/LandingPages/Services/DermaFillers";
import SkinLight from "pages/LandingPages/Services/SkinLight";

const routes = [
  {
    name: "home",
    icon: <Icon>home</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    route: "/",
    component: <Home />,
  },
  // {
  //   name: "about us",
  //   icon: <Icon>info</Icon>,
  //   columns: 1,
  //   rowsPerColumn: 2,
  //   route: "/about-us",
  //   component: <AboutUs />,
  // },
  {
    name: "treatments",
    icon: <Icon>dashboard</Icon>,
    collapse: [
      {
        name: "Pigmentation Treatment",
        dropdown: true,
        collapse: [
          {
            name: "Chemical Peel Treatment",
            route: "/treatments/chemical-peel",
            component: <ChemicalPeel />,
          },
          {
            name: "Laser Toning Treatment",
            route: "/treatments/laser-toning",
            component: <LaserToning />,
          },
          {
            name: "Mesotherapy",
            route: "/treatments/mesotherapy",
            component: <MesoTherapy />,
          },
          {
            name: "Hydra Facial",
            route: "/treatments/hydrafacial",
            component: <Hydrafacial />,
          },
          {
            name: "Carbon Laser Facial",
            route: "/treatments/carbon-laser-facial",
            component: <CarbonLaserFacial />,
          },
        ],
      },
      {
        name: "Laser Hair Removal",
        dropdown: true,
        collapse: [
          {
            name: "Diode",
            route: "/treatments/diode-laser-hair-removal",
            component: <DiodeLaser />,
          },
          {
            name: "IPL",
            route: "/treatments/ipl-treatment",
            component: <IPL />,
          },
        ],
      },
      {
        name: "Laser Tattoo Removal",
        route: "/treatments/pico-laser-tattoo-removal",
        component: <TattooRemoval />,
      },
      {
        name: "Laser Earlobe repair",
        route: "/treatments/laser-earlobe-repair",
        component: <Earlobe />,
      },
      {
        name: "Laser Wart Removal",
        route: "/treatments/laser-wart-removal",
        component: <WartRemoval />,
      },
      {
        name: "Laser Mole Removal",
        route: "/treatments/laser-mole-removal",
        component: <MoleRemoval />,
      },
      {
        name: "Vitiligo",
        dropdown: true,
        collapse: [
          {
            name: "Blister Graphting",
            route: "/treatments/blister-grafting-treatment",
            component: <BlisterGraphting />,
          },
          {
            name: "Excimer Laser",
            route: "/treatments/excimer-laser-treatment",
            component: <Excimer />,
          },
          {
            name: "Micropigmentation",
            route: "/treatments/vitiligo-micropigmentation",
            component: <MicroPigmentation />,
          },
          {
            name: "Punch Graphting",
            route: "/treatments/punch-grafting-for-white-spots",
            component: <PunchGraphting />,
          },
        ],
      },
      {
        name: "Hyperhydrosis Botox Therapy",
        route: "/treatments/hyperhidrosis-botox-treatment",
        component: <HyperHydrosis />,
      },
      {
        name: "Acne Scar",
        dropdown: true,
        collapse: [
          {
            name: "CO2 Fractional Laser",
            route: "/treatments/fractional-co2-laser-for-acne",
            component: <Co2Laser />,
          },
          {
            name: "Dermaroller with PRP & Subcision",
            route: "/treatments/acne-scar-treatment-dermaroller-prp",
            component: <AcnePRP />,
          },
          {
            name: "Erbium Fiber Laser",
            route: "/treatments/acne-scar-erbium-fiber-laser",
            component: <ErbiumFiber />,
          },
        ],
      },
      {
        name: "Hair Fall",
        dropdown: true,
        collapse: [
          {
            name: "PRP with Dermaroller",
            route: "/treatments/prp-treatment-hair",
            component: <DermarollerHair />,
          },
          {
            name: "Hair Transplant",
            route: "/treatments/best-fue-hair-transplant-treatment",
            component: <HairTransplant />,
          },
          {
            name: "Low Light Laser Therapy",
            route: "/treatments/men-women-hair-loss-treatment",
            component: <HairLoss />,
          },
        ],
      },
      {
        name: "Anti Aging",
        dropdown: true,
        collapse: [
          {
            name: "Botox",
            route: "/treatments/botox-anti-ageing-treatment",
            component: <Botox />,
          },
          {
            name: "Fillers",
            route: "/treatments/dermal-fillers-treatment",
            component: <DermaFillers />,
          },
        ],
      },
      {
        name: "Skin Lighting & Whitening",
        route: "/treatments/skin-lightening-whitening-treatment",
        component: <SkinLight />,
      },
    ],
  },
  {
    name: "contact us",
    icon: <Icon>person</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    route: "/contact-us",
    component: <ContactUs />,
  },
];

export default routes;
