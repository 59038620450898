/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/co2.jpeg";

const description = (
  <>
Fractional CO2 laser treatment is a cutting-edge solution for treating acne and acne scars. This advanced laser technology works by creating tiny, controlled micro-injuries in the skin, stimulating the body’s natural healing process. As the skin heals, new collagen is produced, resulting in smoother, clearer, and rejuvenated skin. Ideal for those looking to reduce acne scars and improve skin texture, the Fractional CO2 laser offers long-lasting and visible results.  
  </>
);

function Co2Laser() {
  return (
    <>
      <Helmet>
        <title>Fractional CO2 Laser for Acne - Advanced, Effective, and Rejuvenating</title>
        <meta name="description" content="Experience advanced acne treatment with Fractional CO2 laser. This powerful, collagen-boosting therapy reduces acne scars and rejuvenates the skin, offering smoother and clearer results." />
        <meta name="keywords" content="Fractional CO2 laser for acne, Acne scar treatment with CO2 laser, Advanced acne laser therapy, Collagen-boosting laser treatment, CO2 laser for acne scars, Fractional laser skin rejuvenation, Effective acne scar reduction, Laser treatment for clear skin" />
        <meta property="og:title" content="Fractional CO2 Laser for Acne - Advanced, Effective, and Rejuvenating" />
        <meta property="og:description" content="Experience advanced acne treatment with Fractional CO2 laser. This powerful, collagen-boosting therapy reduces acne scars and rejuvenates the skin, offering smoother and clearer results." />
        <meta property="og:url" content="https://www.skinaamalviyanagar.com/treatments/fractional-co2-laser-for-acne" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Fractional CO2 Laser for Acne - Advanced, Effective, and Rejuvenating
              </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Co2Laser;
