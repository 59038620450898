/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/Chemical-Peel.jpg";

const description = (
  <>
The combination of Dermaroller and Platelet-Rich Plasma (PRP) therapy is a powerful treatment for acne scars, offering a natural and effective solution. Dermaroller, a micro-needling device, creates tiny punctures in the skin, stimulating the body's natural healing process. When combined with PRP, which is rich in growth factors, the treatment accelerates tissue repair and collagen production, leading to smoother, clearer skin. This treatment is ideal for those looking to reduce acne scars and improve skin texture naturally.
  </>
);

function AcnePRP() {
  return (
    <>
      <Helmet>
      <title>Acne Scar Treatment by Dermaroller & PRP - Effective, Natural, and Rejuvenating</title>
        <meta name="description" content="Discover the powerful combination of Dermaroller and PRP therapy for treating acne scars. This natural, collagen-boosting treatment offers visible results, reducing scars and improving skin texture." />
        <meta name="keywords" content="Acne scar treatment by Dermaroller and PRP, PRP therapy for acne scars, Micro-needling acne scar treatment, Natural acne scar reduction, Collagen-boosting acne treatment, Non-surgical acne scar removal, Dermaroller PRP combination therapy, Effective acne scar healing" />
        <meta property="og:title" content="Acne Scar Treatment by Dermaroller & PRP - Effective, Natural, and Rejuvenating" />
        <meta property="og:description" content="Discover the powerful combination of Dermaroller and PRP therapy for treating acne scars. This natural, collagen-boosting treatment offers visible results, reducing scars and improving skin texture." />
        <meta property="og:url" content="https://www.yoursite.com/acne-scar-treatment-dermaroller-prp" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Acne Scar Treatment by Dermaroller & PRP - Effective, Natural, and Rejuvenating
              </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AcnePRP;
