/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/wart.jpg";

const description = (
  <>
   Laser wart removal is a highly effective treatment that uses focused laser energy to safely and precisely eliminate warts from the skin. Warts, caused by the human papillomavirus (HPV), can be unsightly and uncomfortable. Laser treatment targets the blood supply to the wart, causing it to gradually shrink and fall off without damaging the surrounding skin. This method is especially effective for stubborn or recurring warts and offers a quicker recovery with minimal discomfort compared to traditional removal methods.
  </>
);

function WartRemoval() {
  return (
    <>
      <Helmet>
        <title>Laser Wart Removal Treatment - Safe, Precise, and Effective</title>
        <meta name="description" content="Experience safe and precise wart removal with advanced laser technology. Suitable for all skin types, this minimally invasive treatment offers effective results with quick recovery. Choose laser wart removal for a reliable solution to unwanted warts." />
        <meta name="keywords" content="Laser wart removal, Wart removal treatment, Safe wart removal, Effective wart removal with laser, Minimally invasive wart removal, Laser treatment for warts, Quick recovery wart removal, Precise wart removal with laser" />
        <meta property="og:title" content="Laser Wart Removal Treatment - Safe, Precise, and Effective" />
        <meta property="og:description" content="Experience safe and precise wart removal with advanced laser technology. Suitable for all skin types, this minimally invasive treatment offers effective results with quick recovery. Choose laser wart removal for a reliable solution to unwanted warts." />
        <meta property="og:url" content="https://www.skinaamalviyanagar.com/treatments/laser-wart-removal" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Laser Wart Removal Treatment - Safe, Precise, and Effective
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default WartRemoval;
