/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "./sections/Information";
import Featuring from "./sections/Featuring";
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/Chemical-Peel.jpg";

const description = (
  <>
    Discover the transformative power of chemical peels at our clinic. This advanced skin treatment
    targets fine lines, wrinkles, acne scars, and uneven skin tone by exfoliating the top layers of
    the skin to reveal a smoother, more youthful complexion. Our expert dermatologists customize
    each chemical peel to suit your unique skin type and concerns, ensuring optimal results with
    minimal downtime. Experience radiant, refreshed skin with our professional chemical peel
    services, designed to enhance your natural beauty and restore your skin&apos;s healthy glow.
  </>
);

function chemicalPeel() {
  return (
    <>
      <Helmet>
        <title>Chemical Peeling Trearment</title>
        <meta name="description" content="Discover the transformative benefits of chemical peeling at [Your Clinic Name]. Achieve smoother, more youthful skin by addressing fine lines, acne scars, and uneven tone." />
        <meta name="keywords" content= "chemical peel treatment, rejuvenated skin, exfoliating, youthful complexion, expert dermatologists, professional chemical peel services" />
        <meta property="og:title" content="Chemical Peeling - Reveal Radiant, Smooth Skin" />
        <meta property="og:description" content="Experience the benefits of chemical peeling at [Your Clinic Name]. Our treatments reduce pigmentation, smooth texture, and enhance radiance for a flawless complexion." />
        <meta property="og:image" content="https://example.com/image.jpg" />
        <meta property="og:url" content="https://skinaamalviyanagar.com/treatments/chemical-peel" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Chemical Peel Treatment
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Information />
      <Featuring />
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default chemicalPeel;
