/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const FAQ = () => {
const Que = ['What will be the Cost if I Take a Skin Lightening or Whitening Treatment from Skinaa?', 'How Much Time Does it Take to See the Results?', 'Are the Skin Whitening and Lightening Results Permanent?', 'On which areas of my body I can make skin lightening and whitening treatment?'];
const Ans = ['The cost of your treatment will vary depending on several factors. The first factor is the size of the area that needs to be treated. And the second factor will be the type of skin lightening & whitening treatment you choose to take. You can also consult with our dermatologists to know what kind of treatment will work best according to your skin type.', 'You will be able to see the results in approx two to three weeks. Some of the treatments consist of 5 to 7 sessions while others have 6 to 8 sessions.', 'Our skin starts to fade away due to some lifestyle mistakes we make such as exposing ourselves to a lot of pollution without covering our face or eating too much spicy or oily food. By making some changes to your lifestyle, you can make your skin whitening and lightening results remain for a long time. Leading a healthy lifestyle, using face protection in sun and pollution, hydrating yourself & apply moisturizing regularly can keep your skin healthy for a longer amount of time.', 'You can take this treatment for your face, neck, arm, underarms, legs, private part, and even full body.'];

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h4" color="white">
            FAQs
            </MKTypography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
          {Que.map((Que, index) => (
          <Stack key={index}>
            <MKBox  display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                <MKTypography variant="h4" color="text" pl={2}>
                  {Que}
                  </MKTypography>
            </MKBox>
              <MKTypography variant="body" color="text" pl={2}>
                {Ans[index]}
              </MKTypography>
          </Stack>
      ))} 
        </Grid>
      </Container>
    </MKBox>
)};
export default FAQ;
