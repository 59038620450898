/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const FAQ = () => {
const Que = ['How My Moles will Be Treated?', 'Is There Any Risk in Laser Mole Removal Treatment?', 'Does Laser Wart Removal Treatment Hurt?', 'How Many Sessions I would Need to Remove the Mole Completely?', 'Can My Mole Regrow?'];
const Ans = ['At Skinaa Clinic, we have treated a great many numbers of patients with our advanced laser technology which burns the roots of the moles to remove them from your skin.', 'Generally, the mole removal procedure is risk-free. The treated area needs to be healed for a few days. Your dermatologist will prescribe some ointments, medicines, and instructions.', 'No, it does not hurt. You won’t feel any pain as local anesthesia is applied.', 'Only one session is required. It takes no more than 20 mins for each Mole.', 'In 99% of cases, the mole does not regrow as we remove it from its root with the help of a laser. Recurrences happen in cases when the mole has been removed wrongly. At Skinaa, we analyze your mole and then apply the right procedure to minimize the recurrence time.'];

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h4" color="white">
            FAQs (Laser Mole Removal)
            </MKTypography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
          {Que.map((Que, index) => (
          <Stack key={index}>
            <MKBox  display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                <MKTypography variant="h4" color="text" pl={2}>
                  {Que}
                  </MKTypography>
            </MKBox>
              <MKTypography variant="body" color="text" pl={2}>
                {Ans[index]}
              </MKTypography>
          </Stack>
      ))} 
        </Grid>
      </Container>
    </MKBox>
)};
export default FAQ;
