/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/erbium.jpg";

const description = (
  <>
The Erbium Fiber Laser is a state-of-the-art technology for treating acne scars, offering precision and effectiveness in skin resurfacing and scar reduction. This laser works by targeting the water content in the skin, allowing for controlled and precise removal of scar tissue. The treatment promotes the growth of new, healthy skin cells and stimulates collagen production, resulting in smoother and rejuvenated skin. Ideal for those seeking a minimally invasive yet highly effective solution, the Erbium Fiber Laser provides noticeable improvements in skin texture and tone.
  </>
);

function ErbiumFiber() {
  return (
    <>
      <Helmet>
      <title>Acne Scar Treatment by Erbium Fiber Laser - Precision, Effectiveness, and Skin Renewal</title>
        <meta name="description" content="Experience precise and effective acne scar treatment with Erbium Fiber Laser. This advanced laser technology promotes collagen production and skin resurfacing, delivering smoother, rejuvenated skin." />
        <meta name="keywords" content="Acne scar treatment by Erbium Fiber Laser, Erbium laser for acne scars, Precise acne scar removal, Laser skin resurfacing for scars, Collagen-boosting laser treatment, Non-surgical acne scar treatment, Erbium fiber laser for skin rejuvenation, Effective acne scar reduction" />
        <meta property="og:title" content="Acne Scar Treatment by Erbium Fiber Laser - Precision, Effectiveness, and Skin Renewal" />
        <meta property="og:description" content="Experience precise and effective acne scar treatment with Erbium Fiber Laser. This advanced laser technology promotes collagen production and skin resurfacing, delivering smoother, rejuvenated skin." />
        <meta property="og:url" content="https://www.skinaamalviyanagar.com/treatments/acne-scar-erbium-fiber-laser" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Acne Scar Treatment by Erbium Fiber Laser - Precision, Effectiveness, and Skin Renewal
              </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ErbiumFiber;
