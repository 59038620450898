/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/hairloss.jpeg";

const description = (
  <>
Hair loss affects both men and women, often leading to a loss of confidence and self-esteem. Fortunately, a wide range of effective hair loss treatments are available to address this common issue. From medical treatments like Minoxidil and Finasteride to advanced procedures such as PRP Therapy and Hair Transplantation, there is a solution for every type of hair loss. Whether you’re dealing with thinning hair, receding hairlines, or bald patches, tailored treatments can help restore your hair and confidence.
  </>
);

function HairLoss() {
  return (
    <>
      <Helmet>
        <title>Men & Women Hair Loss Treatment - Effective Solutions for All Types of Hair Loss</title>
        <meta name="description" content="Explore effective hair loss treatments for men and women, including FDA-approved medications, PRP Therapy, and Hair Transplantation. Find the best solution for your hair loss needs." />
        <meta name="keywords" content="Men & women hair loss treatment, Hair restoration for men and women, Effective hair loss solutions, Hair thinning treatment for men, Female hair loss treatment, PRP therapy for hair loss, Hair transplant for men and women, FDA-approved hair loss medication" />
        <meta property="og:title" content="Men & Women Hair Loss Treatment - Effective Solutions for All Types of Hair Loss" />
        <meta property="og:description" content="Explore effective hair loss treatments for men and women, including FDA-approved medications, PRP Therapy, and Hair Transplantation. Find the best solution for your hair loss needs." />
        <meta property="og:url" content="https://www.skinaamalviyanagar.com/treatments/men-women-hair-loss-treatment" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
             Men & Women Hair Loss Treatment - Effective Solutions for All Types of Hair Loss
              </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default HairLoss;
