/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/pigmentation.jpg";

const description = (
  <>
   Micropigmentation, also known as medical tattooing, is a specialized treatment for vitiligo that involves implanting pigment into the skin to match the surrounding areas. This technique is particularly effective for patients with stable vitiligo who seek to improve their skin’s appearance by camouflaging white spots. Performed by experienced professionals in Jaipur, micropigmentation offers a non-invasive solution that provides long-lasting results.
  </>
);

function MicroPigmentation() {
  return (
    <>
      <Helmet>
        <title>Vitiligo Treatment in Jaipur by Micropigmentation - Precise, Safe, and Effective</title>
        <meta name="description" content="Discover effective and long-lasting vitiligo treatment in Jaipur with micropigmentation. This non-invasive technique provides precise color matching for white spots, offering a reliable solution for stable vitiligo." />
        <meta name="keywords" content="Vitiligo treatment in Jaipur, Micropigmentation for vitiligo, Medical tattooing for white spots, Non-invasive vitiligo treatment in Jaipur, Effective vitiligo camouflage technique, Safe vitiligo pigmentation treatment, Long-lasting vitiligo solution, Skin pigmentation for vitiligo" />
        <meta property="og:title" content="Vitiligo Treatment in Jaipur by Micropigmentation - Precise, Safe, and Effective" />
        <meta property="og:description" content="Discover effective and long-lasting vitiligo treatment in Jaipur with micropigmentation. This non-invasive technique provides precise color matching for white spots, offering a reliable solution for stable vitiligo." />
        <meta property="og:url" content="https://www.skinaamalviyanagar.com/treatments/vitiligo-micropigmentation" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Vitiligo Treatment in Jaipur by Micropigmentation - Precise, Safe, and Effective
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default MicroPigmentation;
