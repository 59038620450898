/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const myList = ['Minimally Invasive Procedure: The laser earlobe repair procedure is minimally invasive, using precise laser energy to target and correct damaged earlobe tissue. This approach reduces the need for stitches and results in minimal scarring, making it a popular choice for those concerned about the appearance of their earlobes.', 'Quick Recovery Time: One of the significant advantages of laser earlobe repair is the quick recovery time. Patients can expect minimal discomfort during the healing process and can often return to their normal routines shortly after the procedure.', 'Safe for All Skin Types: Laser earlobe repair is safe for all skin types, making it an inclusive treatment option. The procedure&amps;s settings can be adjusted to match the unique needs of each patient, ensuring effective results across a diverse range of skin tones.', 'Effective Results: The primary goal of laser earlobe repair is to restore the natural appearance and function of the earlobes. The procedure delivers effective, natural-looking results, helping patients regain confidence in their appearance.'];
const myComponentList = myList.map((item, index) => (
  <li key={index}>{item}</li>
));
  
function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
      <MKTypography variant="h3" gutterBottom>
          Laser Earlobe Repair Treatment: Restoring Natural Beauty
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Laser earlobe repair offers a minimally invasive solution for those looking to restore the natural appearance of their earlobes. Whether your earlobes have been stretched due to heavy earrings, trauma, or gauging, laser technology provides an effective means of repair. This treatment focuses on precision and minimizes scarring, offering a quick recovery process that allows patients to resume their daily activities with minimal disruption.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Why Choose Laser Earlobe Repair?
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Laser earlobe repair stands out as a preferred choice for those seeking a safe and effective treatment. The procedure uses targeted laser energy to address damaged tissue, reducing the need for extensive stitching and promoting faster healing. Additionally, laser earlobe repair is suitable for all skin types, with customizable settings to ensure optimal results for each patient.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits of Laser Earlobe Repair
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            {myComponentList}
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
