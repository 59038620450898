/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const myList = ['Natural Acne Scar Treatment: Dermaroller and PRP work together to naturally stimulate skin repair and rejuvenation, offering a holistic approach to acne scar treatment.', 'Enhanced Collagen Production: The micro-needling action of the Dermaroller combined with PRP’s growth factors significantly boosts collagen production, leading to firmer, smoother skin.', 'Minimally Invasive: This non-surgical treatment has minimal downtime, allowing you to resume your daily activities quickly while enjoying the benefits of a professional-grade treatment.', 'Visible Results: Over time, you’ll notice a significant reduction in the appearance of acne scars, along with an overall improvement in skin texture and tone.'];
const myComponentList = myList.map((item, index) => (
  <li key={index}>{item}</li>
));

function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
          <MKTypography variant="h3" gutterBottom>
          Acne Scar Treatment by Dermaroller & PRP: Effective and Natural Skin Rejuvenation
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Acne scars can be a persistent concern, but the combination of Dermaroller and PRP therapy offers a powerful, natural solution. Dermaroller, a micro-needling device, creates tiny punctures in the skin, which triggers the body’s natural healing response. When paired with PRP (Platelet-Rich Plasma), which is derived from your own blood and rich in growth factors, the healing process is accelerated, promoting the production of new collagen and improving skin texture.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Why Choose Dermaroller & PRP for Acne Scar Treatment?
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          This combination therapy not only addresses acne scars effectively but also enhances overall skin health. Dermaroller stimulates the skin to regenerate, while PRP boosts the healing process, leading to faster and more pronounced results. The treatment is minimally invasive, making it a safe and convenient option for those seeking natural skin rejuvenation.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits of Dermaroller & PRP for Acne Scars
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            {myComponentList}
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
