/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const myList = ['Advanced Tattoo Removal Technology: Pico laser is at the forefront of tattoo removal technology, offering superior results by breaking down ink particles more efficiently than traditional lasers.', 'Effective on All Ink Colors: One of the standout features of Pico laser is its effectiveness on all ink colors, including challenging shades like green and blue. This makes it an ideal solution for those looking to remove or fade tattoos with difficult-to-treat inks.', 'Minimal Discomfort: The ultra-short pulses used in Pico laser treatments significantly reduce discomfort, making the procedure more tolerable for patients. The reduced heat buildup also means that the skin heals faster, allowing for quicker recovery.', 'Safe for All Skin Types: Pico laser is a versatile treatment that can be safely used on all skin types. The ability to adjust the laser settings ensures that each patient receives a customized treatment that is both safe and effective.', 'Fast Recovery Tattoo Removal: Thanks to the advanced technology of Pico laser, patients can enjoy faster recovery times compared to traditional laser treatments. This means fewer disruptions to your daily routine and a quicker return to normal activities.'];
const myComponentList = myList.map((item, index) => (
  <li key={index}>{item}</li>
));
  
function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
      <MKTypography variant="h3" gutterBottom>
          Laser Tattoo Removal by Pico Laser: A Revolutionary Approach
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Laser tattoo removal by Pico laser represents the latest advancement in tattoo removal technology. Utilizing ultra-short pulses of energy, Pico laser effectively targets tattoo ink, breaking it down into minuscule particles that the body can naturally eliminate. This method is particularly effective on stubborn ink colors like green and blue, which are often resistant to traditional laser treatments.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Why Choose Pico Laser for Tattoo Removal?
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Pico laser tattoo removal is a preferred choice for many due to its ability to provide safe and effective results with minimal discomfort. The ultra-short pulses reduce the amount of heat generated during the procedure, leading to less pain and faster recovery times. Moreover, Pico laser technology is safe for all skin types, ensuring that more people can benefit from this advanced treatment.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits of Pico Laser Tattoo Removal
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            {myComponentList}
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
