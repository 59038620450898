/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits:
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            <li>Deep Cleansing: The carbon lotion penetrates deep into the pores, absorbing oil, dirt, and dead skin cells, which are then vaporized by the laser.</li>
            <li>Reduces Acne: Effectively targets acne-causing bacteria, reducing breakouts and preventing future acne flare-ups.</li>
            <li>Minimizes Pores: Tightens and refines pores, giving your skin a smoother, more even appearance.</li>
            <li>Enhances Skin Tone: Evens out skin tone and reduces pigmentation, leaving you with a brighter, clearer complexion.</li>
            <li>Stimulates Collagen Production: Promotes collagen growth, improving skin elasticity and reducing fine lines and wrinkles.</li>
          </MKTypography>
          <MKTypography variant="h4" paragraph>
          The Carbon Laser Facial is suitable for all skin types and provides immediate results with no downtime. Whether you're dealing with acne, oily skin, or simply want to rejuvenate your complexion, this treatment offers a safe, effective solution. Schedule your consultation at Skinaa Clinic Malviya Nagar today and discover how a Carbon Laser Facial can transform your skin.
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
