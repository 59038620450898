/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const FAQ = () => {
const Que = ['How Much Time the Blister Grafting Treatment Take?', 'Can I Take This Treatment Even If My Vitiligo is Unstable?', 'Is This Treatment Safe?'];
const Ans = ['This treatment can take anywhere from 2 hours to several hours. It completely depends on the size of the patient’s vitiligo spot and also the number of grafts. For bigger spots and where more grafts are required, this treatment takes more time.', 'Blister grafting treatment is a perfect solution whether your vitiligo is stable. For unstable vitiligo, the patient needs to take medication treatment to make it stable.', 'Yes, the blister grafting treatment is 100% safe. There are usually no side effects. An expert derma surgeon will handle your surgery.'];

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h4" color="white">
            FAQs (Blister Graphting )
            </MKTypography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
          {Que.map((Que, index) => (
          <Stack key={index}>
            <MKBox  display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                <MKTypography variant="h4" color="text" pl={2}>
                  {Que}
                  </MKTypography>
            </MKBox>
              <MKTypography variant="body" color="text" pl={2}>
                {Ans[index]}
              </MKTypography>
          </Stack>
      ))} 
        </Grid>
      </Container>
    </MKBox>
)};
export default FAQ;
