/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits:
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            <li>Skin Renewal: Exfoliates dead skin cells to reveal a fresh, glowing complexion.</li>
            <li>Improves Texture: Smooths rough skin and minimizes the appearance of fine lines and acne scars.</li>
            <li>Boosts Collagen: Stimulates collagen production for firmer, more youthful skin.</li>
            <li>Enhances Radiance: Brightens dull skin and evens out skin tone for a luminous glow.</li>
          </MKTypography>
          <MKTypography variant="h4" paragraph>
          Achieve radiant, youthful skin with our professional microdermabrasion services. Contact us at +91-7300009731 to book your appointment and experience the transformative effects of this advanced skin treatment.
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
