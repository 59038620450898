/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/carbon laser facial.jpg";

const description = (
  <>
   Experience the rejuvenating effects of a Carbon Laser Facial at [Your Clinic Name], a cutting-edge treatment designed to deeply cleanse, exfoliate, and revitalize your skin. This advanced facial uses a carbon lotion and Q-Switched laser to target and eliminate impurities, reduce acne, minimize pores, and improve overall skin tone and texture.
  </>
);

function CarbonLaserFacial() {
  return (
    <>
      <Helmet>
      <title>Carbon Laser Facial - Clear, Glowing Skin with Advanced Laser Technology</title>
      <meta name="description" content="Discover the benefits of a Carbon Laser Facial at Skinaa Clinic Malviya Nagar. This advanced treatment deeply cleanses, reduces acne, minimizes pores, and improves skin tone for a clear, glowing complexion." />
      <meta name="keywords" content="carbon laser facial, deep cleansing, acne treatment, pore reduction, skin rejuvenation, clear skin, glowing complexion, laser skin treatment, collagen production" />
      <meta property="og:title" content="Carbon Laser Facial: Achieve Clear, Glowing Skin with Advanced Laser Technology" />
      <meta property="og:description" content="Experience the transformative effects of a Carbon Laser Facial at Skinaa Clinic Malviya Nagar. This treatment cleanses deeply, reduces acne, and enhances skin tone and texture for a radiant look." />
      <meta property="og:image" content="https://example.com/carbon-laser-facial-image.jpg" />
      <meta property="og:url" content="https://www.skinaamalviyanagar.com/treatments/carbon-laser-facial" />
      <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Carbon Laser Facial: Achieve Clear, Glowing Skin with Advanced Laser Technology
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default CarbonLaserFacial;
