/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/excimer.jpg";

const description = (
  <>
   The Excimer Laser is a cutting-edge treatment designed to effectively target and reduce white spots on the skin, commonly caused by conditions like vitiligo. This advanced laser therapy focuses on delivering UVB light to the affected areas, stimulating melanocyte activity and promoting repigmentation. The Excimer Laser is known for its precision, making it ideal for treating localized white spots while minimizing exposure to surrounding healthy skin.
  </>
);

function Excimer() {
  return (
    <>
      <Helmet>
        <title>Excimer Laser Treatment for White Spots - Targeted, Effective, and Safe</title>
        <meta name="description" content="Experience targeted and effective treatment for white spots with the Excimer Laser. This safe and non-invasive therapy uses precise UVB light to stimulate repigmentation in conditions like vitiligo, delivering quick and reliable results." />
        <meta name="keywords" content="Excimer Laser treatment, White spots laser therapy, Vitiligo treatment with Excimer Laser, Safe UVB laser therapy, Effective white spot treatment, Non-invasive laser for vitiligo, Targeted UVB therapy for white spots, Precise laser treatment for depigmentation" />
        <meta property="og:title" content="Excimer Laser Treatment for White Spots - Targeted, Effective, and Safe" />
        <meta property="og:description" content="Experience targeted and effective treatment for white spots with the Excimer Laser. This safe and non-invasive therapy uses precise UVB light to stimulate repigmentation in conditions like vitiligo, delivering quick and reliable results." />
        <meta property="og:url" content="https://www.skinaamalviyanagar.com/treatments/excimer-laser-treatment" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Excimer Laser Treatment for White Spots - Targeted, Effective, and Safe
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Excimer;
