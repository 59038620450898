/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const myList = ['Targeted Treatment: Punch grafting allows for precise targeting of white spots, ensuring that the healthy skin is transplanted exactly where it’s needed. This precision enhances the effectiveness of the treatment and ensures a natural-looking result.', 'Minimally Invasive: As a minimally invasive procedure, punch grafting requires only small incisions, reducing recovery time and minimizing discomfort. Patients can expect a swift return to their normal activities following the procedure.', 'Suitable for Stable Vitiligo: Punch grafting is most effective for individuals with stable vitiligo, where the white spots have remained unchanged for an extended period. This ensures that the results are long-lasting and consistent.', 'Natural-Looking Results: The transplanted skin from punch grafting blends seamlessly with the surrounding skin, providing a natural appearance that boosts the patient’s confidence.'];
const myComponentList = myList.map((item, index) => (
  <li key={index}>{item}</li>
));
  
function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>






      <MKTypography variant="h3" gutterBottom>
      Punch Grafting for White Spots: A Precise and Effective Solution
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Punch grafting is an advanced surgical method specifically designed to treat white spots on the skin, such as those caused by vitiligo. This technique involves removing small, circular sections of healthy skin from a donor site and transplanting them into areas with depigmentation. In Jaipur, skilled dermatologists use punch grafting as a minimally invasive procedure that offers lasting and natural-looking results.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Why Choose Punch Grafting for White Spots?
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Punch grafting is particularly beneficial for patients with stable vitiligo. The procedure targets only the affected areas, allowing for precise treatment and ensuring that the transplanted skin matches the surrounding areas. This method is known for its reliability and effectiveness in restoring skin color and texture.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits of Punch Grafting
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            {myComponentList}
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
