/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Content from "./sections/content";
import FAQ from "./sections/faq";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/services/hair tranplant.jpeg";

const description = (
  <>
The Follicular Unit Extraction (FUE) Hair Transplant is recognized as one of the most advanced and effective methods for hair restoration. This minimally invasive technique involves extracting individual hair follicles from a donor area and implanting them into the thinning or balding regions of the scalp. FUE is celebrated for its precision, natural-looking results, and minimal scarring, making it a preferred choice for those seeking permanent hair restoration. Whether you’re dealing with male pattern baldness or thinning hair, FUE offers a reliable solution with a high success rate.
  </>
);

function HairTransplant() {
  return (
    <>
      <Helmet>
      <title>Best FUE Hair Transplant Treatment - Precision, Natural Results, and Minimal Scarring</title>
        <meta name="description" content="Discover the best FUE Hair Transplant treatment, offering natural-looking results with minimal scarring. This advanced hair restoration technique ensures permanent results and a quick recovery." />
        <meta name="keywords" content="Best FUE hair transplant treatment, Advanced FUE hair restoration, Natural hair transplant results, Follicular Unit Extraction for hair loss, Permanent hair restoration solution, Minimal scarring hair transplant, FUE technique for hair regrowth, Effective hair transplant procedure" />
        <meta property="og:title" content="Best FUE Hair Transplant Treatment - Precision, Natural Results, and Minimal Scarring" />
        <meta property="og:description" content="Discover the best FUE Hair Transplant treatment, offering natural-looking results with minimal scarring. This advanced hair restoration technique ensures permanent results and a quick recovery." />
        <meta property="og:url" content="https://www.skinaamalviyanagar.com/treatments/best-fue-hair-transplant-treatment" />
        <meta property="og:type" content="website" />
      </Helmet>
      <DefaultNavbar routes={routes} transparent light action={{
            type: "external",
            route: "https://wa.me/917300009731",
            label: "Contact Now",
            color: "info",
          }} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
             Best FUE Hair Transplant Treatment - Precision, Natural Results, and Minimal Scarring
              </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Content />
      <FAQ />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default HairTransplant;
