/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";


const FAQ = () => {
const Que = ['What is the Cause of Acne?', 'What Are Some Good Tips to Prevent Acne Problem?', 'What is the Difference Between Acne & Acne Scars?'];
const Ans = ['When dead skin and oil generated from sebaceous glands stuck into a person’s hair follicles, acne appears. It is also called a bacteria. Some people believe that acne is caused by impure blood. However, that’s completely wrong.', 'As acne is caused by excess oil, dead skin, and minute impurities like dirt, it is very essential to keep your face clean. So, get a very gentle face wash or cleanser and wash your face at least 3 times per day. Skin moisturization is also important. Overusing makeup can also cause acne so keep it at a minimum.', 'Acne is a red, bumpy pore that is caused by sebum, dead skin cells, and bacteria. Sometimes it takes to form a pustule when the pus stucks in it. Acne scars are the scars (uneven skin surface) caused by chronic and severe acne.'];

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h4" color="white">
            FAQs (Acne Scars Treatment by CO2 Fractional Laser)
            </MKTypography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
          {Que.map((Que, index) => (
          <Stack key={index}>
            <MKBox  display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                <MKTypography variant="h4" color="text" pl={2}>
                  {Que}
                  </MKTypography>
            </MKBox>
              <MKTypography variant="body" color="text" pl={2}>
                {Ans[index]}
              </MKTypography>
          </Stack>
      ))} 
        </Grid>
      </Container>
    </MKBox>
)};
export default FAQ;
