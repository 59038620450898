/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const myList = ['Targeted Treatment: This treatment is designed to target only the white spots, ensuring that the surrounding skin remains unaffected. This precision reduces the risk of scarring and promotes a more uniform skin tone.', 'Safe and Minimally Invasive: Blister grafting is a safe procedure suitable for all skin types. Since it is minimally invasive, patients experience minimal discomfort during and after the procedure, with a quick recovery time.', 'Effective for Small Areas: Blister grafting is especially effective for smaller areas of depigmentation. The precision of the technique ensures that the treatment is both effective and aesthetically pleasing.', 'Promotes Natural Repigmentation: The transplanted skin cells stimulate the natural repigmentation of the white spots. Over time, this leads to a more even skin tone and a significant reduction in the visibility of the white spots.'];
const myComponentList = myList.map((item, index) => (
  <li key={index}>{item}</li>
));
  
function content() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
      <MKTypography variant="h3" gutterBottom>
      Blister Grafting Treatment for White Spots: Safe and Effective
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Blister grafting is an innovative dermatological treatment specifically designed to tackle white spots, such as those caused by vitiligo. This procedure involves creating small blisters on the patient’s normally pigmented skin using gentle suction. These blisters are then carefully transplanted onto the areas of depigmentation. Over time, the new skin cells in these grafts begin to repopulate the white spots, gradually restoring natural skin color.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Why Choose Blister Grafting for White Spots?
          </MKTypography>
          <MKTypography variant="body1" gutterBottom>
          Blister grafting is ideal for patients seeking a targeted, non-invasive solution to white spots. The procedure is particularly effective for treating small, localized areas of depigmentation, where precision is key. The minimally invasive nature of blister grafting means that it requires no stitches, and recovery time is minimal.
          </MKTypography>
          <MKTypography variant="h3" gutterBottom>
          Key Benefits of Blister Grafting
          </MKTypography>
          <MKTypography variant="body1" component="ul">
            {myComponentList}
          </MKTypography>
        </Container>
      </MKBox>
  );
}

export default content;
