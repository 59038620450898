/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const FAQ = () => {
const Que = ['How Many Days Does it Take to Show Results?', 'How does Botox treatment take place?', 'How Much Time Does this Procedure Take?', 'What Are the Aftercare Instructions for Botox Patients?'];
const Ans = ['Your results will begin to appear in a week. You will notice that you are no longer seeing those wrinkly lines or that sagging skin. In a span of just two to three weeks, your results will be fully noticeable.', 'First, the technician or dermatologist will clean the area of treatment. Later, the muscles that are causing the wrinkles will be marked with a marker. Now, the botox will be injected into the marked part.', 'The botox therapy is pretty fast. The complete procedure will be done in less than half an hour.', 'You shouldn’t touch the treated area for at least 6 hours after the treatment. Neither you should rub it or lie down on it. For at least 6 hours to 1 day, your dermatologist might suggest you not to carry out any exercise or intense physical activity. The patient should avoid alcohol and painkillers as well.'];

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h4" color="white">
            FAQs
            </MKTypography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
          {Que.map((Que, index) => (
          <Stack key={index}>
            <MKBox  display="flex" alignItems="center" p={2}>
              <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">keyboard_double_arrow_right</Icon>
                </MKBox>
                <MKTypography variant="h4" color="text" pl={2}>
                  {Que}
                  </MKTypography>
            </MKBox>
              <MKTypography variant="body" color="text" pl={2}>
                {Ans[index]}
              </MKTypography>
          </Stack>
      ))} 
        </Grid>
      </Container>
    </MKBox>
)};
export default FAQ;
